import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import AddCompany from '@/components/Management/asm/AddCompany';
import AddUser from '@/components/Management/asm/AddUser';
import Clients from '@/components/Management/asm/Clients';
import Companies from '@/components/Management/asm/Companies';
import UserManagement from '@/components/Management/asm/UserManagement';
import CustomTabPanel from '@/components/Tab/CustomTabPanel';
import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import useAuth from '@/Hooks/fetchApi/useAuth';
import { CompanyType, UserType } from '@/types/management';
import { decrypt } from '@/utils/crypto';

const AsmManagement = () => {
  const { t } = useTranslation();
  const { handleGetListUser, handleGetListCompany } = useAuth();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [value, setValue] = useState(0);
  const [isAddUser, setIsAddUser] = useState(false);
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [listUsers, setListUsers] = useState<UserType[]>([]);
  const [listCompanies, setListCompanies] = useState<CompanyType[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsAddUser(false);
    setIsAddCompany(false);
  };

  const handleGetUser = async () => {
    const dataRes = await handleGetListUser();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_groups } = dataRes;
    setListUsers(list_groups);
  };
  const handleGetCompany = async () => {
    const dataRes = await handleGetListCompany();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_company } = dataRes;
    setListCompanies(list_company);
  };

  useEffect(() => {
    handleGetCompany();
    handleGetUser();
  }, []);

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('management.asm.titleAsm')}
      </Typography>

      <Box sx={{ mt: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <StyledTab
              sx={{
                '&::after': {
                  content: `"${listCompanies.length}"`,
                  position: 'absolute',
                  width: '15px',
                  height: '15px',
                  bottom: { xs: '5px', sm: '10px' },
                  right: 0,
                  color: 'rgba(255, 255, 255, 0.8)',
                  backgroundColor: '#1976d2',
                  borderRadius: '50%',
                  fontSize: '11px',
                  paddingTop: '1px',
                },
              }}
              label={t('management.asm.clients')}
            />
            <StyledTab
              sx={{
                '&::after': {
                  content: `"${listCompanies.length}"`,
                  position: 'absolute',
                  width: '15px',
                  height: '15px',
                  bottom: { xs: '5px', sm: '10px' },
                  right: 0,
                  color: 'rgba(255, 255, 255, 0.8)',
                  backgroundColor: '#1976d2',
                  borderRadius: '50%',
                  fontSize: '11px',
                  paddingTop: '1px',
                },
              }}
              label={t('management.asm.companies')}
            />
            {inforUser.role !== 'super admin' ? (
              <StyledTab
                sx={{
                  '&::after': {
                    content: `"${_.isArray(listUsers[0]) ? listUsers[0]?.length : 0}"`,
                    position: 'absolute',
                    width: '15px',
                    height: '15px',
                    bottom: { xs: '5px', sm: '10px' },
                    right: 0,
                    color: 'rgba(255, 255, 255, 0.8)',
                    backgroundColor: '#1976d2',
                    borderRadius: '50%',
                    fontSize: '11px',
                    paddingTop: '1px',
                  },
                }}
                label={t('management.asm.users')}
              />
            ) : (
              <StyledTab
                sx={{
                  '&::after': {
                    content: `"${listUsers?.length}"`,
                    position: 'absolute',
                    width: '15px',
                    height: '15px',
                    bottom: { xs: '5px', sm: '10px' },
                    right: 0,
                    color: 'rgba(255, 255, 255, 0.8)',
                    backgroundColor: '#1976d2',
                    borderRadius: '50%',
                    fontSize: '11px',
                    paddingTop: '1px',
                  },
                }}
                label={t('management.asm.groups.groups')}
              />
            )}
          </StyledTabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Clients listCompanies={listCompanies} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {!isAddCompany ? (
            <Companies
              listCompanies={listCompanies}
              setIsAddCompany={setIsAddCompany}
              handleGetUser={handleGetUser}
              handleGetCompany={handleGetCompany}
            />
          ) : (
            <AddCompany
              listUsers={listUsers}
              listCompanies={listCompanies}
              setIsAddCompany={setIsAddCompany}
              handleGetUser={handleGetUser}
              handleGetCompany={handleGetCompany}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {!isAddUser ? (
            <UserManagement
              listUsers={listUsers}
              setIsAddUser={setIsAddUser}
              handleGetUser={handleGetUser}
              handleGetCompany={handleGetCompany}
            />
          ) : (
            <AddUser setIsAddUser={setIsAddUser} handleGetUser={handleGetUser} handleGetCompany={handleGetCompany} />
          )}
        </CustomTabPanel>
      </Box>
    </>
  );
};
export default AsmManagement;
