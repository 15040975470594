import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  CardMedia,
  InputBase,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import AddCourse from '@/components/Management/learning/AddCourse';
import useManagenent from '@/Hooks/fetchApi/useManagenent';
import { Course } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

const LearningManagement = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleGetListCourses } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listCourses, setListCourses] = useState<Course[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const handleGetCourses = async () => {
    const params = {
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };
    const dataRes = await handleGetListCourses(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_course, total_courses } = dataRes;
    setListCourses(list_course);
    setTotalPage(total_courses);
  };

  useEffect(() => {
    handleGetCourses();
  }, [searchParams.get('page')]);

  const handleChangePage = (pageValue: number) => {
    setSearchParams({ page: pageValue.toString() });
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('management.training.titleTraining')}
      </Typography>

      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '10px 10px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                'aria-label': 'filter company',
              }}
              endAdornment={<></>}
              // value={searchValue}
              // onChange={handleInputChange}
            />
          </Box>

          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setOpenDialogCreate(true);
            }}
            sx={{
              width: '180px',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('action.addCourse')}
          </Button>
        </Box>
        <Box>
          <TableContainer
            sx={{
              padding: 2,
              borderRadius: 2,
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: 'action.hover',
              mt: 3,
            }}
          >
            <Table aria-label="simple table" sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'action.hover' }}>
                  <TableCell width="10%">{t('table.courseManagement.id')}</TableCell>
                  <TableCell>{t('table.courseManagement.title')}</TableCell>
                  <TableCell>{t('table.courseManagement.thumbnail')}</TableCell>
                  <TableCell>{t('table.courseManagement.linkVideo')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {!_.isEmpty(listCourses) ? (
                    <>
                      {listCourses.map((row: Course) => (
                        <TableRow
                          key={row.id}
                          hover
                          tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row.id}</TableCell>
                          <TableCell sx={{ lineBreak: 'anywhere' }}>{row.title}</TableCell>
                          <TableCell sx={{ lineBreak: 'anywhere' }}>
                            <Link href={`${row.thumbnail}`} target="_blank" rel="noopener noreferrer">
                              {row.thumbnail}
                            </Link>

                            <Box sx={{ marginTop: 2 }}>
                              <CardMedia
                                component="img"
                                image={row.thumbnail}
                                alt="Thumbnail Preview"
                                sx={{ height: 100, width: 'auto', borderRadius: 1 }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell sx={{ lineBreak: 'anywhere' }}>
                            <Link href={`${row.link_video}`} target="_blank" rel="noopener noreferrer">
                              {row.link_video}
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        {t('notify.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
              <Pagination
                count={Math.ceil(totalPage / PAGE_SIZE)}
                color="primary"
                onChange={(event, value) => {
                  handleChangePage(value);
                }}
                page={Number(searchParams.get('page') || 1)}
              />
            </Box>
          </TableContainer>
        </Box>
      </Box>
      <AddCourse openDialogCreate={openDialogCreate} onDialogCreateChange={setOpenDialogCreate} />
    </>
  );
};
export default LearningManagement;
