import { useTheme, Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

interface IProps {
  count: Count;
  animation: boolean;
}
interface Count {
  crit: number;
  high: number;
  medium: number;
  low: number;
  info: number;
}
const ChartDonut = ({ count, animation }: IProps) => {
  const theme = useTheme();
  const styles: any = {
    series:
      Object.keys(count).length !== 0
        ? [count.crit || 0, count.high || 0, count.medium || 0, count.low || 0, count.info || 0]
        : [0.1],
    options: {
      chart: {
        width: '100%',
        type: 'donut',
        background: 'transparent',
        animations: {
          enabled: !animation,
        },
      },
      labels: Object.keys(count).length !== 0 ? ['Critical', 'High', 'Medium', 'Low', 'Info'] : [''],
      colors:
        Object.keys(count).length !== 0 ? ['#9c27b0', '#f44336', '#FFC107FF', '#0DCAF0FF', '#20C997FF'] : '#1e88e5',
      theme: {
        mode: theme.palette.mode === 'dark' ? 'dark' : 'light',
      },
      dataLabels: {
        enabled: true,
      },
      tooltip: {
        enabled: Object.keys(count).length !== 0,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                fontSize: '30px',
                fontWeight: 800,
              },
              total: {
                show: true,
                fontSize: '20px',
                label: 'Total',
                color: theme.palette.text.primary,
                formatter(w: any) {
                  const total = w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0);
                  return Math.round(total);
                },
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              show: true,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: true,
            },
          },
        },
      ],
      legend: {
        position: 'right',
        offsetY: 0,
        height: 230,
        lineHeight: '40px',
        fontSize: '14px',
        formatter(seriesName: string, opts: any) {
          if (Object.keys(count).length !== 0) {
            return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]];
          }
          return seriesName;
        },
      },
    },
  };
  return (
    <Box sx={{ marginTop: 4 }}>
      <ReactApexChart options={styles.options} series={styles.series} type="donut" width={380} />
    </Box>
  );
};
export default ChartDonut;
