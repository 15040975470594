/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

import { urlApi } from './configs/Api';

const firebaseConfig = {
  apiKey: 'AIzaSyDN7nBocnclXAqggjPnxHluGkryxptt4lc',
  authDomain: 'asm-app-828e4.firebaseapp.com',
  databaseURL: 'https://asm-app-828e4.firebaseio.com',
  projectId: 'asm-app-828e4',
  storageBucket: 'asm-app-828e4.appspot.com',
  messagingSenderId: '95462780704',
  appId: '1:95462780704:web:ad7d675fe291d7a44ef145',
  measurementId: 'G-S6FKL3LB3M',
};

initializeApp(firebaseConfig);

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging();
    }
    console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

const callApiFcm = async (currentToken: string, type: string) => {
  // call api send fcm token
  const params = { fcm_token: currentToken, type };
  await axios
    .post(urlApi.notification.sendFcmToken, params)
    .then((response) => {
      if (type === 'delete' && response.status === 200) {
        localStorage.removeItem('fcmToken');
      }
    })
    .catch((error) => {
      console.error('Error during API request:', error);
    });
};

export const requestForToken = async (type: string) => {
  try {
    const messagingInstance = await messaging;
    if (messagingInstance) {
      const currentToken = await getToken(messagingInstance, {
        vapidKey: 'BGWgkJiV06fJtgLqS1zl5sjZLRgTWZK6TADXfsEBe1-Ge4D5xq4ev3a3Qh_0S_2_HC-O3zOg1hDkpZA2vk6ljt0',
      });
      if (currentToken) {
        localStorage.setItem('fcmToken', JSON.stringify(currentToken));
        await callApiFcm(currentToken, type);
      }
    }
  } catch (err) {
    const token = localStorage.getItem('fcmToken') as string;
    await callApiFcm(token, type);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.then((messagingInstance) => {
      if (messagingInstance) {
        onMessage(messagingInstance, (payload) => {
          resolve(payload);
        });
      }
    });
  });
