import { useTranslation } from 'react-i18next';

export const MenuHomeHeader = () => {
  const { t } = useTranslation();

  const menuItemHeader = [
    {
      label: t('home.label.home'),
      path: '/',
    },
    {
      label: t('home.label.services.services'),
      path: '/score',
    },
    {
      label: t('home.label.news.news'),
      path: '/news',
    },
    {
      label: t('home.label.cve'),
      path: '/cve',
    },
    {
      label: t('home.label.contact'),
      path: '/contact',
    },
  ];

  const services = [
    {
      name: 'asm',
      label: t('home.label.services.asm'),
      src: '/score',
    },
    {
      name: 'search',
      label: t('home.label.services.investigate'),
      src: '/identity',
    },
    {
      name: 'phishing',
      label: t('home.label.services.phishing'),
      src: '/phishing',
    },
    {
      name: 'social',
      label: t('home.label.services.smcc'),
      src: '/social',
    },
    {
      name: 'malware',
      label: t('home.label.services.malware'),
      src: '/malware',
    },
    {
      name: 'learning',
      label: t('home.label.services.learning'),
      src: '/learning',
    },
  ];

  const news = [
    {
      name: 'cyberSecurity',
      label: t('home.label.news.cyberSecurity'),
      src: '/news/cyber-security',
    },
    {
      name: 'business',
      label: t('home.label.news.business'),
      src: '/news/business',
    },
  ];

  return { menuItemHeader, services, news };
};
