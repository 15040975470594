import React, { ComponentProps, useEffect, useState } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, useTheme, Typography, Grid, Link as LinkMui, styled } from '@mui/material';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import AosComponent from '@/components/animation/AosComponent';
import FormatTimeNews from '@/components/common/format/FormatTimeNews';
import { TooltipCustom } from '@/components/common/LightTooltip';
import SubPostLabel from '@/components/News/SubPostLabel';
import { NewsConfig } from '@/configs/NewsConfig';
import useAsm from '@/Hooks/fetchApi/useAsm';
import { NewsTypes } from '@/types/news';
import { PAGE_SIZE } from '@/utils/constants';

const NewsDetail = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { handleDetailNews, handleListNews } = useAsm();
  const locale = localStorage.getItem('locale') || 'vi';
  const { checkValidUrl } = NewsConfig();

  const [list, setList] = useState([]);
  const [detail, setDetail] = useState<NewsTypes>();

  const getIdFromPath = (url: string) => {
    return url.split('-')[url.split('-').length - 1];
  };

  const handleGetDetail = async (path: string) => {
    const id = getIdFromPath(path);
    const detailRes = await handleDetailNews({ id });
    setDetail(detailRes.detail_news);

    const listParams = {
      page: 1,
      page_size: PAGE_SIZE,
      field: 'all',
      type_news: detailRes?.detail_news?.type_news,
    };
    const listRes = await handleListNews(listParams);

    const top5News = listRes.list_news
      .filter((article: NewsTypes) => article.id !== detailRes.detail_news.id)
      .slice(0, 6);
    setList(top5News);
  };

  useEffect(() => {
    const pathname = location.pathname.replace('/news/', '');
    if (!isNaN(+pathname.split('-')[pathname.split('-').length - 1])) {
      handleGetDetail(location.pathname);
    } else {
      navigate('*');
    }
  }, []);

  const StyledMarkdown = styled(ReactMarkdown)(() => ({
    '& ul': {
      paddingLeft: '12px',
    },
    '& li': {
      marginLeft: '12px',
    },
    '& p:not(:has(*))': {
      marginLeft: '12px',
    },
    '& li::marker': {
      paddingLeft: '12px',
    },
    '& a': {
      // wordBreak: 'break-all',
      color: '#deb887',
      '&:hover': {
        color: '#dda5a5',
      },
    },
    '& strong': {
      color: 'text.secondary',
    },
  }));

  const removeLastNonNumericCharacter = (inputString: string) => inputString?.replace(/[^0-9]$/, '');

  const linkCVE = (text: string) => {
    const words = text.split(' ');
    return (
      <p>
        {words.map((word: any, index: number) =>
          // Kiểm tra xem từ có chứa "CVE-" không
          word.includes('CVE-') ? (
            <LinkMui
              sx={{
                marginRight: '5px',
                fontSize: '1.3rem',
                color: '#8DCAFE !important',
                textDecoration: 'none',
                '&:hover': {
                  color: '#3e97e3 !important',
                  textDecoration: 'underline',
                },
              }}
              key={index}
              href={`/cve/${removeLastNonNumericCharacter(word)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {word}
            </LinkMui>
          ) : (
            <span key={index}>{word} </span>
          ),
        )}
      </p>
    );
  };

  const components = {
    h1: (props: ComponentProps<'h1'>) => {
      const { children } = props;
      return <h1>{children}</h1>;
    },
    p: (props: any) => {
      const { children } = props;
      const content = typeof children === 'string' ? children : children.join('');

      // Kiểm tra xem nội dung có chứa cụm từ "CVE-" không
      if (typeof content === 'string' && content.includes('CVE-')) {
        return <p>{linkCVE(content)}</p>;
      }
      return <p>{children}</p>;
    },
    li: (props: any) => {
      const { children } = props;
      const content = typeof children === 'string' ? children : children.join('');

      // Kiểm tra xem nội dung có chứa cụm từ "CVE-" không
      if (typeof content === 'string' && content.includes('CVE-')) {
        return <li>{linkCVE(content)}</li>;
      }
      return <li>{children}</li>;
    },
  };

  const checkTypeAndDisplay = (field: string) => {
    if (field === 'stock') {
      return t('news.stock');
    }
    if (field === 'real_estate') {
      return t('news.realEstate');
    }
    if (field === 'finance') {
      return t('news.finance');
    }
    if (field === 'news_summary') {
      return t('news.summary');
    }
    return field;
  };

  return (
    <>
      <Helmet>
        <title>{locale === 'vi' ? detail?.title_vi : detail?.title_en}</title>
        <meta property="og:title" content={detail?.title_vi} />
        <meta property="og:description" content={detail?.content_vi} />
        <meta property="og:image" content={detail?.img_url || ''} />
      </Helmet>

      {!_.isEmpty(detail) && (
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '1200px',
            alignSelf: 'center',
            minHeight: '100vh',
            paddingTop: { md: '130px', xs: '90px' },
            padding: 2,
            mb: 4,
          }}
        >
          <AosComponent dataAos="fade-up" dataAosDuration="1000">
            <Typography
              variant="h4"
              sx={{
                color: 'text.primary',
                fontWeight: 600,
              }}
            >
              {locale === 'vi' ? detail?.title_vi : detail?.title_en}
            </Typography>
          </AosComponent>
          <Grid container sx={{ justifyContent: 'space-between', mt: 4 }}>
            <Grid item xs={12} md={8}>
              <AosComponent dataAos="fade-up" dataAosDuration="1000">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex' }}>
                      <AccessTimeIcon sx={{ color: 'text.secondary', alignSelf: 'center' }} />
                      <TooltipCustom title={format(new Date(detail?.create_time), 'HH:mm, dd-MM-yyyy')}>
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            ml: 1,
                            alignSelf: 'center',
                            fontSize: '1.4rem',
                            cursor: 'pointer',
                            '&:hover': {
                              color: 'text.primary',
                            },
                            transition: ' color 0.4s ease',
                          }}
                        >
                          <FormatTimeNews publishDate={detail?.create_time} />
                        </Typography>
                      </TooltipCustom>
                    </Box>
                    <Box sx={{ display: 'flex', marginLeft: { md: '40px', xs: '20px' } }}>
                      <VisibilityIcon
                        sx={{
                          color: 'text.secondary',
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          ml: 1,
                          alignSelf: 'center',
                          fontSize: '1.4rem',
                          position: 'relative',
                        }}
                      >
                        {detail?.views || 1}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex', mr: 4 }}>
                    <Box sx={{ display: 'flex' }}>
                      <Box
                        sx={{
                          alignSelf: 'center',
                          fontSize: '1.4rem',
                          textTransform: 'capitalize',
                          ml: 1,
                          color: 'primary.light',
                        }}
                      >
                        {checkTypeAndDisplay(detail?.field)}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </AosComponent>
              <AosComponent dataAos="fade-up" dataAosDuration="1000">
                <Box
                  component="img"
                  src={checkValidUrl(detail.img_url, detail.image_url_minio)}
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    mt: 4,
                    cursor: 'pointer',
                    borderRadius: 2,
                    width: '100%',
                    maxHeight: '500px',
                    alignSelf: 'center',
                  }}
                />
              </AosComponent>
              <Box sx={{ mt: 3 }}>
                <AosComponent dataAos="fade-up" dataAosDuration="1000">
                  <Box
                    sx={{
                      fontSize: { xs: '1.6rem', md: '1.8rem' },
                      mt: 1,
                      color: 'text.secondary',
                      fontWeight: 400,
                      lineHeight: 1.5,
                    }}
                  >
                    <StyledMarkdown components={components} linkTarget="_blank">
                      {locale === 'vi' ? detail?.content_vi : detail?.content_en}
                    </StyledMarkdown>
                  </Box>
                </AosComponent>
              </Box>
              <Box sx={{ mt: 4, pb: 4, textAlign: '-webkit-center' }}>
                <AosComponent dataAos="fade-up" dataAosDuration="1000">
                  <Link
                    to={detail?.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', width: 'fit-content' }}
                  >
                    <Typography
                      sx={{
                        maxWidth: '200px',
                        padding: 1.5,
                        color: 'primary.main',
                        fontSize: '1.4rem',
                        whiteSpace: 'nowrap',
                        borderRadius: 1,
                        '&:hover': {
                          color: 'primary.light',
                        },
                        transition: 'color 0.3s ease',
                      }}
                    >
                      {t('news.moreDetail')} <ChevronRightIcon sx={{ verticalAlign: 'text-bottom' }} />
                    </Typography>
                  </Link>
                </AosComponent>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: { xs: 4, md: 0 } }}>
              <SubPostLabel label="news.mostRecent" />

              <Grid container spacing={{ xs: 2, md: 0 }}>
                {list?.map((item: NewsTypes, index: number) => (
                  <React.Fragment key={item.id}>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={12}
                      sx={{
                        display: 'flex',
                        mt: 1,
                      }}
                      key={item.id}
                    >
                      <AosComponent dataAos="fade-left" dataAosDuration="1000">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={5}
                            onClick={() => {
                              setDetail(item);
                              window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                              });
                            }}
                            sx={{
                              cursor: 'pointer',
                              borderRadius: 1,
                              overflow: 'hidden',
                              border: `1px solid ${theme.palette.divider}`,
                              ':hover': {
                                '.img': {
                                  transform: 'scale(1.1)',
                                },
                              },
                              mt: { xs: 0, md: 1 },
                              width: '160px',
                            }}
                          >
                            <Box
                              component="img"
                              sx={{ width: '100%', height: { xs: '100px', md: '90px' }, transition: '0.6s' }}
                              className="img"
                              alt=""
                              src={checkValidUrl(item.img_url, item.image_url_minio)}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              mt: { xs: 2, md: 1 },
                              paddingBottom: { xs: 0, md: 1 },
                              minWidth: { md: 0, lg: '150px' },
                            }}
                          >
                            <Link to={`/news/${item.path}`} style={{ textDecoration: 'none' }}>
                              <Box
                                onClick={() => {
                                  handleGetDetail(item.path);
                                  window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                  });
                                }}
                                sx={{
                                  marginLeft: { xs: 0, md: 2 },
                                  fontWeight: 600,
                                  fontSize: '1.4rem',
                                  color: 'text.primary',
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: '#02BB9F',
                                  },
                                  transition: 'color 0.3s ease',
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  WebkitLineClamp: { xs: 3, sm: 4 },
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {locale === 'vi' ? item.title_vi : item.title_en}
                              </Box>
                            </Link>
                          </Grid>
                        </Grid>
                      </AosComponent>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
export default NewsDetail;
