import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import DetailTopic from '@/components/Social/topic/DetailTopic';
import useSocial from '@/Hooks/fetchApi/useSocial';
import useSetTopicSocial from '@/Hooks/social/useSetTopicSocial';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';

interface TableTopicProps {
  listTopics: any;
  onSearchValueChange: (newValue: string) => void;
  onPageChange: (newValue: number) => void;
  page: number;
  searchValue: string;
  handleGetListTopic: (pageValue: number) => void;
  handleFilterTopic: any;
  totalTopic: number;
  loadingFilter: boolean;
}

const TableTopic: React.FC<TableTopicProps> = ({
  listTopics,
  onSearchValueChange,
  onPageChange,
  page,
  searchValue,
  handleGetListTopic,
  handleFilterTopic,
  totalTopic,
  loadingFilter,
}) => {
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const theme = useTheme();
  const { t } = useTranslation();
  const { setTopicSocial } = useSetTopicSocial();
  const { handleDeleteTopic } = useSocial();
  const navigate = useNavigate();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [topicIdSelected, setTopicIdSelected] = useState('');
  const [detailTopic, setDetailTopic] = useState({});

  const deleteSuccess = () => {
    setOpenDialogDelete(false);
    onSearchValueChange('');
    if (page === 1) {
      handleGetListTopic(1);
    } else {
      onPageChange(1);
    }
  };

  const handleDelete = () => {
    handleDeleteTopic(
      {
        topic_id: topicIdSelected,
      },
      deleteSuccess,
    );
  };

  const handleChangePage = (pageValue: number) => {
    onPageChange(pageValue);
    if (searchValue) {
      handleFilterTopic(searchValue, pageValue);
    }
  };

  return (
    <>
      <TableContainer
        sx={{
          padding: 2,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'action.hover',
          mt: 2,
        }}
      >
        <Table aria-label="simple table" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'action.hover' }}>
              <TableCell sx={{ width: '40%' }}>{t('table.topicSmcc.topic')}</TableCell>
              <TableCell sx={{ width: '40%' }}>{t('table.topicSmcc.createAt')}</TableCell>
              <TableCell sx={{ width: '20%' }}> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {!_.isEmpty(listTopics) ? (
                <>
                  {listTopics.map((item: any, index: number) => (
                    <TableRow
                      key={index}
                      hover
                      tabIndex={-1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        onClick={() => {
                          setTopicSocial(item);
                          navigate('/social/overview');
                        }}
                        sx={{
                          fontWeight: 600,
                          cursor: 'pointer',
                          color: 'primary.main',
                          '&:hover': {
                            color: 'text.primary',
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {item.topic_name}
                      </TableCell>
                      <TableCell>{item.created_at}</TableCell>
                      <TableCell sx={{ minWidth: '100px', textAlign: 'center' }}>
                        <Tooltip title={t('tooltip.detail')} placement="bottom">
                          <InfoIcon
                            onClick={() => {
                              setDetailTopic(item);
                              setOpenDialogDetail(true);
                            }}
                            sx={{
                              fontSize: '2rem',
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        </Tooltip>
                        {inforUser.role !== 'super admin' && (
                          <Tooltip title={t('tooltip.delete')} placement="bottom">
                            <DeleteIcon
                              onClick={() => {
                                setTopicIdSelected(item.topic_id);
                                setOpenDialogDelete(true);
                              }}
                              sx={{
                                fontSize: '2rem',
                                ml: 3,
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'error.main',
                                },
                              }}
                            />
                          </Tooltip>
                        )}
                        <ConfirmDeleteModal
                          openDialogDelete={openDialogDelete}
                          setOpenDialogDelete={setOpenDialogDelete}
                          handleDelete={handleDelete}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {!loadingFilter && (
                    <TableRow>
                      <TableCell colSpan={6} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        {t('notify.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </>
          </TableBody>
        </Table>
        {!loadingFilter && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Pagination
              count={Math.ceil(totalTopic / PAGE_SIZE)}
              color="primary"
              onChange={(event, value) => {
                handleChangePage(value);
              }}
              page={page}
            />
          </Box>
        )}
      </TableContainer>
      <DetailTopic
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        page={page}
        handleGetListTopic={handleGetListTopic}
        detail={detailTopic}
        onPageChange={onPageChange}
      />
    </>
  );
};
export default TableTopic;
