import { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useManagenent from '@/Hooks/fetchApi/useManagenent';
import { PAGE_SIZE } from '@/utils/constants';

import DetailNews from './DetailNews';
import UpdateNews from './UpdateNews';

interface TableNewsProps {
  listNews: any;
  onPageChange: (newValue: number) => void;
  page: number;
  handleGetListNews: () => void;
  totalNews: number;
}

const TableNews: React.FC<TableNewsProps> = ({ listNews, onPageChange, page, handleGetListNews, totalNews }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleDeleteNews } = useManagenent();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [newsIdSelected, setNewsIdSelected] = useState('');
  const [detailNews, setDetailNews] = useState({});

  const deleteSuccess = () => {
    setOpenDialogDelete(false);
    if (page === 1) {
      handleGetListNews();
    } else {
      onPageChange(1);
    }
  };

  const handleDelete = () => {
    handleDeleteNews(
      {
        id: newsIdSelected,
      },
      deleteSuccess,
    );
  };

  const handleChangePage = (pageValue: number) => {
    onPageChange(pageValue);
  };

  return (
    <>
      <TableContainer
        sx={{
          padding: 2,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'action.hover',
          mt: 2,
        }}
      >
        <Table aria-label="simple table" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'action.hover' }}>
              <TableCell>{t('table.newsManagement.id')}</TableCell>
              <TableCell>{t('table.newsManagement.title')}</TableCell>
              <TableCell>{t('table.newsManagement.typeNews')}</TableCell>
              <TableCell>{t('table.newsManagement.field')}</TableCell>
              <TableCell>{t('table.newsManagement.image')}</TableCell>
              <TableCell>{t('table.newsManagement.createAt')}</TableCell>
              <TableCell>{t('table.newsManagement.analytic')}</TableCell>
              <TableCell sx={{ width: '20%' }}> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {!_.isEmpty(listNews) ? (
                <>
                  {listNews.map((item: any, index: number) => (
                    <TableRow
                      key={index}
                      hover
                      tabIndex={-1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.title_vi}</TableCell>
                      <TableCell>{item.type_news}</TableCell>
                      <TableCell>{item.field}</TableCell>
                      <TableCell>
                        <Box component="img" src={item.image_url_minio || item.image_url} sx={{ width: '100%' }} />
                      </TableCell>
                      <TableCell>{item.create_time}</TableCell>
                      <TableCell>
                        {item.is_status_analytic_news ? (
                          <ErrorOutlineIcon sx={{ color: 'error.main', fontSize: '2rem' }} />
                        ) : (
                          <CheckIcon sx={{ color: 'success.main', fontSize: '2rem' }} />
                        )}
                      </TableCell>
                      <TableCell sx={{ minWidth: '100px', textAlign: 'center' }}>
                        <Tooltip title={t('tooltip.detail')} placement="bottom">
                          <InfoIcon
                            onClick={() => {
                              setDetailNews(item);
                              setOpenDialogDetail(true);
                            }}
                            sx={{
                              fontSize: '2rem',
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'text.secondary',
                              },
                            }}
                          />
                        </Tooltip>

                        <Tooltip title={t('tooltip.edit')} placement="bottom">
                          <EditIcon
                            onClick={() => {
                              setDetailNews(item);
                              setOpenDialogUpdate(true);
                            }}
                            sx={{
                              fontSize: '2rem',
                              ml: 3,
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        </Tooltip>

                        <Tooltip title={t('tooltip.delete')} placement="bottom">
                          <DeleteIcon
                            onClick={() => {
                              setNewsIdSelected(item.id);
                              setOpenDialogDelete(true);
                            }}
                            sx={{
                              fontSize: '2rem',
                              ml: 3,
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'error.main',
                              },
                            }}
                          />
                        </Tooltip>

                        <ConfirmDeleteModal
                          openDialogDelete={openDialogDelete}
                          setOpenDialogDelete={setOpenDialogDelete}
                          handleDelete={handleDelete}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                    {t('notify.noData')}
                  </TableCell>
                </TableRow>
              )}
            </>
          </TableBody>
        </Table>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalNews / PAGE_SIZE)}
            color="primary"
            onChange={(event, value) => {
              handleChangePage(value);
            }}
            page={page}
          />
        </Box>
      </TableContainer>
      <DetailNews openDialogDetail={openDialogDetail} onDialogDetailChange={setOpenDialogDetail} detail={detailNews} />
      <UpdateNews
        openDialogUpdate={openDialogUpdate}
        onDialogUpdateChange={setOpenDialogUpdate}
        detail={detailNews}
        onPageChange={onPageChange}
        page={page}
        handleGetListNews={handleGetListNews}
      />
    </>
  );
};
export default TableNews;
