import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useManagementService = () => {
  const { methodGet, methodPost, methodPostUpload } = useRequest();

  const listNews = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.listNews, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const editNews = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.editNews, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteNews = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.deleteNews, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListPermissionIdentity = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.listPermissionIdentity, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const permissionIdentity = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.permissionIdentity, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getList2FA = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.list2fa, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const disable2FA = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.disable2fa, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const uploadCourse = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPostUpload(urlApi.manage.uploadCourse, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListCourses = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.listCourses, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListReport = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.reportNews, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const sendReportNews = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.reportNews, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    listNews,
    editNews,
    deleteNews,
    getListPermissionIdentity,
    permissionIdentity,
    getList2FA,
    disable2FA,
    uploadCourse,
    getListCourses,
    getListReport,
    sendReportNews,
  };
};

export default useManagementService;
