/* eslint-disable @typescript-eslint/naming-convention */

import React, { useEffect, useState } from 'react';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ShareIcon from '@mui/icons-material/Share';
import {
  useTheme,
  Box,
  Grid,
  Chip,
  Typography,
  TableRow,
  TableCell,
  Stack,
  Breadcrumbs,
  Link,
  Table,
  TableHead,
  TableBody,
  Skeleton,
  Divider,
} from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Home from '@/assets/image/Home.png';
import { formatTimeCVEDetail } from '@/components/common/format/FormatTimeCVE';
import useBreakpoints from '@/helpers/useBreakpoints';
import useAsm from '@/Hooks/fetchApi/useAsm';

import Header from '../HomePage/Header';
import HeaderMobile from '../HomePage/HeaderMobile';

interface PropsCvssV3Label {
  point: number;
}

interface PropsCvssV3Detail {
  label: string;
  state: string;
}
interface BaseMetricV2 {
  cvssData: {
    accessVector: '';
    vectorString: '';
    authentication: '';
    integrityImpact: '';
    accessComplexity: '';
    availabilityImpact: '';
    confidentialityImpact: '';
    baseScore: 0;
  };
  impactScore: '';
  exploitabilityScore: '';
}

interface BaseMetricV3 {
  cvssData: {
    scope: '';
    attackVector: '';
    vectorString: '';
    integrityImpact: '';
    userInteraction: '';
    attackComplexity: '';
    availabilityImpact: '';
    privilegesRequired: '';
    confidentialityImpact: '';
    baseScore: 0;
  };
  impactScore: '';
  exploitabilityScore: '';
}

const CVEDetails = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useBreakpoints();
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleListCVE } = useAsm();
  const { id } = useParams();

  const [cvssType, setCvssType] = useState('v3');
  const [cveDetail, setCveDetail] = useState({
    summary: '',
    updated: '',
    published: '',
    v2: 0,
    v3: 0,
    vendors: {},
    cwes: [],
    reference: [],
    baseMetricV2: {} as BaseMetricV2,
    baseMetricV3: {} as BaseMetricV3,
    configurations: [],
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => {
        navigate('/cve');
      }}
      sx={{ cursor: 'pointer' }}
    >
      CVE
    </Link>,
    <Typography key="2" color="text.primary" sx={{ fontSize: isMobile ? '1.4rem' : '1.6rem', fontWeight: '500' }}>
      {id}
    </Typography>,
  ];

  useEffect(() => {
    handleDetailCVE();
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleDetailCVE = async () => {
    setLoading(true);
    // gọi list cve
    const dataRes = await handleListCVE({ id });
    const { detailCve } = dataRes;
    setMessage(detailCve?.message);
    if (!detailCve?.message) {
      setCveDetail({
        summary: detailCve.summary,
        updated: detailCve?.updated_at,
        published: detailCve?.created_at,
        v2: detailCve.cvss.v2,
        v3: detailCve.cvss.v3,
        vendors: detailCve.vendors,
        cwes: detailCve.cwes,
        reference: detailCve.raw_nvd_data.references,
        baseMetricV2:
          (detailCve.raw_nvd_data.metrics?.cvssMetricV2 && detailCve.raw_nvd_data.metrics?.cvssMetricV2[0]) || null,
        baseMetricV3:
          (detailCve.raw_nvd_data.metrics?.cvssMetricV31 && detailCve.raw_nvd_data.metrics?.cvssMetricV31[0]) || null,
        configurations: detailCve.raw_nvd_data.configurations,
      });
      if (!detailCve.raw_nvd_data.metrics?.cvssMetricV31) {
        setCvssType('v2');
      }
    }
    setLoading(false);
  };

  const CvssLabelTab: React.FC<PropsCvssV3Label> = ({ point }) => {
    const stateCvss = (value: number) => {
      if (value >= 0.1 && value <= 3.9) {
        return { label: t('cve.cvss.low'), bgr: '#00c0ef' };
      }
      if (value >= 4.0 && value <= 6.9) {
        return { label: t('cve.cvss.medium'), bgr: '#f39c12' };
      }
      if (value >= 7.0 && value <= 8.9) {
        return { label: t('cve.cvss.high'), bgr: '#dd4b39' };
      }
      if (value >= 9.0 && value <= 10.0) {
        return { label: cvssType === 'v3' ? t('cve.cvss.critical') : t('cve.cvss.high'), bgr: '#972b1e' };
      }
      return { label: 'N/A', bgr: '#d2d6de' };
    };
    const cvssState = stateCvss(point);
    return (
      <Typography
        sx={{
          color: '#fff',
          backgroundColor: cvssState.bgr,
          fontWeight: 700,
          paddingInline: 1,
          borderRadius: '4px',
          alignSelf: 'center',
          marginLeft: 1,
          fontSize: '1.2rem',
          whiteSpace: 'nowrap',
          textTransform: 'uppercase',
        }}
      >
        {point} {cvssState.label}
      </Typography>
    );
  };

  const CvssLabel: React.FC<PropsCvssV3Label> = ({ point }) => {
    const stateCvss = (value: number) => {
      if (value >= 0.1 && value <= 3.9) {
        return { label: t('cve.cvss.low'), bgr: '#00c0ef' };
      }
      if (value >= 4.0 && value <= 6.9) {
        return { label: t('cve.cvss.medium'), bgr: '#f39c12' };
      }
      if (value >= 7.0 && value <= 8.9) {
        return { label: t('cve.cvss.high'), bgr: '#dd4b39' };
      }
      if (value >= 9.0 && value <= 10.0) {
        return { label: cvssType === 'v3' ? t('cve.cvss.critical') : t('cve.cvss.high'), bgr: '#972b1e' };
      }
      return { label: 'N/A', bgr: '#d2d6de' };
    };
    const cvssState = stateCvss(point);

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: cvssState.bgr }}>
        <Box sx={{ display: 'flex', marginLeft: 1.5 }}>
          <Typography sx={{ fontSize: '3rem', fontWeight: 700, color: '#fff' }}>{point}</Typography>
          <Typography sx={{ fontSize: '2rem', fontWeight: 700, marginLeft: 1, alignSelf: 'center', color: '#fff' }}>
            /10
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', marginLeft: 1.5 }}>
          <Typography variant="body2" sx={{ color: '#fff' }}>
            {' '}
            {cvssType === 'v3' ? 'CVSS v3 :' : 'CVSS v2.0 :'}
          </Typography>
          <Typography variant="body2" sx={{ marginLeft: 1, color: '#fff', textTransform: 'uppercase' }}>
            {cvssState.label}
          </Typography>
        </Box>
        <Box sx={{ backgroundColor: 'rgba(0,0,0,0.1)', textAlign: 'center', padding: 0.5, marginTop: 2 }}>
          <Link
            href={
              cvssType === 'v3'
                ? 'https://www.first.org/cvss/specification-document'
                : 'https://www.first.org/cvss/v2/guide'
            }
            target="_blank"
            sx={{
              fontSize: '1.4rem',
              textDecoration: 'none',
              cursor: 'pointer',
              color: '#f5f5f5',
              '&:hover': {
                color: '#cfd8dc',
              },
            }}
          >
            {cvssType === 'v3' ? 'V3 Legend ' : 'V2 Legend '}
            <ArrowCircleRightIcon sx={{ verticalAlign: 'sub' }} />
          </Link>
        </Box>
      </Box>
    );
  };

  const colorState = (value: string, label: string) => {
    if (value === 'HIGH' && label === 'Privileges Required') {
      return { bgColor: '#d2d6de', color: '#212B36' };
    }
    if (value === 'HIGH' && label === 'Access Complexity') {
      return { bgColor: '#d2d6de', color: '#212B36' };
    }
    if (value === 'HIGH' && label === 'Attack Complexity') {
      return { bgColor: '#f39c12', color: '#ffff' };
    }
    if (value === 'HIGH' || value === 'CHANGED' || value === 'NETWORK') {
      return { bgColor: '#E74646', color: '#ffff' };
    }
    if (value === 'LOW' && (label === 'Attack Complexity' || label === 'Access Complexity')) {
      return { bgColor: '#E74646', color: '#ffff' };
    }
    if (value === 'LOW' || value === 'LOCAL' || value === 'REQUIRED' || value === 'PARTIAL' || value === 'MEDIUM') {
      return { bgColor: '#f39c12', color: '#ffff' };
    }
    if (
      value === 'NONE' &&
      (label === 'User Interaction' || label === 'Authentication' || label === 'Privileges Required')
    ) {
      return { bgColor: '#E74646', color: '#ffff' };
    }
    if (value === 'NONE' || value === 'UNCHANGED') {
      return { bgColor: '#d2d6de', color: '#212B36' };
    }
    if (value === 'SINGLE') {
      return { bgColor: '#f39c12', color: '#ffff' };
    }
    return { bgColor: '#d2d6de', color: '#212B36' };
  };

  const CvssV3Detail: React.FC<PropsCvssV3Detail> = ({ label, state }) => {
    const { bgColor, color } = colorState(state, label);
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          border: `1px solid ${theme.palette.divider}`,
          padding: 1,
          marginBottom: 1,
        }}
      >
        <Typography sx={{ fontSize: '1.4rem', color: `${theme.palette.text.secondary}` }}>{label}</Typography>
        <Typography
          sx={{
            color,
            backgroundColor: bgColor,
            fontWeight: 700,
            paddingInline: 1,
            borderRadius: '4px',
            alignSelf: 'center',
            fontSize: '1rem',
          }}
        >
          {state}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{id} - ESS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.dark',
          zIndex: `${theme.zIndex.drawer + 1}`,
          color: `${theme.palette.grey[600]}`,
          boxShadow: 'none',
          borderBottom: 'none',
          padding: 2,
        }}
      >
        {isMobile || isTablet ? <HeaderMobile /> : <Header />}

        <Grid
          container
          direction="column"
          sx={{ mt: '70px', left: 0, right: 0, minHeight: '100vh', padding: { xs: 0, md: 2 } }}
        >
          <Grid item>
            <Stack spacing={2} sx={{ marginBottom: 2 }}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Grid>
          {message && (
            <Stack
              sx={{
                borderTop: `5px solid ${theme.palette.divider}`,
                backgroundColor: 'background.main',
                padding: 2,
                borderRadius: '3px',
                boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('notify.noData')}
              </Typography>
            </Stack>
          )}
          {!message && (
            <>
              {!loading ? (
                <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid container sx={{ justifyContent: 'space-between' }}>
                    <Grid item md={9.3} xs={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          borderRadius: '3px',
                          borderTop: `5px solid ${theme.palette.divider}`,
                          backgroundColor: 'background.main',
                          padding: 2,
                          color: `${theme.palette.text.secondary}`,
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                        }}
                      >
                        <span style={{ float: 'left', fontSize: '3rem', lineHeight: '36px', marginRight: '10px' }}>
                          {cveDetail.summary[0]}
                        </span>
                        {cveDetail.summary.slice(1)}
                      </Typography>
                      {cveDetail.baseMetricV3 !== null || cveDetail.baseMetricV2 !== null ? (
                        <Box
                          sx={{
                            marginTop: 2,
                            backgroundColor: 'background.main',
                            borderRadius: '3px',
                            boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            {cveDetail.baseMetricV3 !== null && (
                              <Box
                                onClick={() => {
                                  setCvssType('v3');
                                }}
                                sx={{
                                  cursor: 'pointer',
                                  display: 'flex',
                                  borderTop: cvssType === 'v3' ? `5px solid ${theme.palette.divider}` : 'none',
                                  borderRight: `1px solid ${theme.palette.divider}`,
                                  borderBottom: cvssType === 'v2' ? `1px solid ${theme.palette.divider}` : 'none',
                                  padding: 1,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ color: cvssType === 'v3' ? 'text.primary' : 'text.secondary' }}
                                >
                                  CVSS v3
                                </Typography>
                                <CvssLabelTab point={cveDetail?.baseMetricV3?.cvssData?.baseScore} />
                              </Box>
                            )}
                            {cveDetail.baseMetricV2 !== null && (
                              <Box
                                onClick={() => {
                                  setCvssType('v2');
                                }}
                                sx={{
                                  cursor: 'pointer',
                                  display: 'flex',
                                  borderTop: cvssType === 'v2' ? `5px solid ${theme.palette.divider}` : 'none',
                                  borderRight: cvssType === 'v2' ? `1px solid ${theme.palette.divider}` : 'none',
                                  borderBottom: cvssType === 'v3' ? `1px solid ${theme.palette.divider}` : 'none',
                                  padding: 1,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ color: cvssType === 'v2' ? 'text.primary' : 'text.secondary' }}
                                >
                                  CVSS v2.0
                                </Typography>
                                <CvssLabelTab point={cveDetail?.baseMetricV2?.cvssData?.baseScore} />
                              </Box>
                            )}

                            <Box sx={{ width: '100%', borderBottom: `1px solid ${theme.palette.divider}` }} />
                          </Box>
                          <Grid container spacing={2} sx={{ padding: 2 }}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 1, md: 0 } }}
                            >
                              <CvssLabel
                                point={
                                  cvssType === 'v3'
                                    ? cveDetail?.baseMetricV3?.cvssData?.baseScore
                                    : cveDetail?.baseMetricV2?.cvssData?.baseScore
                                }
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontWeight: 700,
                                      whiteSpace: 'nowrap',
                                      color: `${theme.palette.text.secondary}`,
                                    }}
                                  >
                                    Vector :
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      marginLeft: 1,

                                      alignSelf: 'end',
                                      color: `${theme.palette.text.secondary}`,
                                    }}
                                  >
                                    {cvssType === 'v3' ? '' : cveDetail?.baseMetricV2?.cvssData?.vectorString}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 700, color: `${theme.palette.text.secondary}` }}
                                  >
                                    {t('cve.cveDetail.exploitability')}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ marginLeft: 1, color: `${theme.palette.text.secondary}` }}
                                  >
                                    {cvssType === 'v3'
                                      ? cveDetail.baseMetricV3?.exploitabilityScore
                                      : cveDetail.baseMetricV2?.exploitabilityScore}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ marginLeft: 1, color: `${theme.palette.text.secondary}` }}
                                  >
                                    /
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ marginLeft: 1, fontWeight: 700, color: `${theme.palette.text.secondary}` }}
                                  >
                                    {t('cve.cveDetail.impact')}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ marginLeft: 1, color: `${theme.palette.text.secondary}` }}
                                  >
                                    {cvssType === 'v3'
                                      ? cveDetail.baseMetricV3?.impactScore
                                      : cveDetail.baseMetricV2?.impactScore}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            {cvssType === 'v3' ? (
                              <>
                                <Grid item md={4} xs={12}>
                                  <CvssV3Detail
                                    label="Attack Vector"
                                    state={cveDetail.baseMetricV3?.cvssData?.attackVector}
                                  />
                                  <CvssV3Detail
                                    label="Attack Complexity"
                                    state={cveDetail.baseMetricV3?.cvssData?.attackComplexity}
                                  />
                                  <CvssV3Detail
                                    label="Privileges Required"
                                    state={cveDetail.baseMetricV3?.cvssData?.privilegesRequired}
                                  />
                                  <CvssV3Detail
                                    label="User Interaction"
                                    state={cveDetail.baseMetricV3?.cvssData?.userInteraction}
                                  />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <CvssV3Detail
                                    label="Confidentiality Impact"
                                    state={cveDetail.baseMetricV3?.cvssData?.confidentialityImpact}
                                  />
                                  <CvssV3Detail
                                    label="Integrity Impact"
                                    state={cveDetail.baseMetricV3?.cvssData?.integrityImpact}
                                  />
                                  <CvssV3Detail
                                    label="Availability Impact"
                                    state={cveDetail.baseMetricV3?.cvssData?.availabilityImpact}
                                  />
                                  <CvssV3Detail label="Scope" state={cveDetail.baseMetricV3?.cvssData?.scope} />
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item md={4} xs={12}>
                                  <CvssV3Detail
                                    label="Access Vector"
                                    state={cveDetail.baseMetricV2?.cvssData?.accessVector}
                                  />
                                  <CvssV3Detail
                                    label="Access Complexity"
                                    state={cveDetail.baseMetricV2?.cvssData?.accessComplexity}
                                  />
                                  <CvssV3Detail
                                    label="Authentication"
                                    state={cveDetail.baseMetricV2?.cvssData?.authentication}
                                  />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <CvssV3Detail
                                    label="Confidentiality Impact"
                                    state={cveDetail.baseMetricV2?.cvssData?.confidentialityImpact}
                                  />
                                  <CvssV3Detail
                                    label="Integrity Impact"
                                    state={cveDetail.baseMetricV2?.cvssData?.integrityImpact}
                                  />
                                  <CvssV3Detail
                                    label="Availability Impact"
                                    state={cveDetail.baseMetricV2?.cvssData?.availabilityImpact}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            borderTop: `5px solid ${theme.palette.divider}`,
                            marginTop: 2,
                            backgroundColor: 'background.main',
                            padding: 2,
                            borderRadius: '3px',
                            boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                          }}
                        >
                          <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                            CVSS
                          </Typography>
                          <Box
                            sx={{
                              marginTop: 1,
                              padding: 1,
                              backgroundColor: 'background.main',
                              fontSize: '1.4rem',
                              color: 'text.secondary',
                              boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                            }}
                          >
                            {t('cve.cveDetail.noCVSS')}
                          </Box>
                        </Box>
                      )}

                      {/* References */}
                      <Stack
                        sx={{
                          borderTop: `5px solid ${theme.palette.divider}`,
                          marginTop: 2,
                          backgroundColor: 'background.main',
                          padding: 2,
                          borderRadius: '3px',
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                        }}
                      >
                        <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                          {t('cve.cveDetail.references')}
                        </Typography>
                        {!_.isEmpty(cveDetail.reference) ? (
                          <Table sx={{ marginTop: 2, width: '100%' }} size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>{t('cve.cveDetail.link')}</TableCell>
                                <TableCell>{t('cve.cveDetail.resource')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {cveDetail.reference.map((item: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Link
                                      href={item.url}
                                      target="_blank"
                                      color="text.primary"
                                      sx={{
                                        textDecoration: 'none',
                                        fontSize: '1.4rem',
                                        wordBreak: 'break-word',
                                        color: `${theme.palette.info.main}`,
                                        '&:hover': {
                                          textDecoration: 'underline',
                                          cursor: 'pointer',
                                        },
                                      }}
                                    >
                                      {item.url}
                                    </Link>
                                  </TableCell>
                                  <TableCell width="40%">
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', md: 'row' },
                                      }}
                                    >
                                      {item.tags?.map((itemTags: string, indexTags: number) => (
                                        <Box key={indexTags} sx={{ padding: 0.5 }}>
                                          <Chip
                                            sx={{
                                              backgroundColor: '#d2d6de',
                                              height: '20px',
                                              color: '#161C24',
                                              fontWeight: 600,
                                              '& .MuiChip-label': {
                                                fontSize: { xs: '1rem', md: '1.1rem' },
                                                padding: { xs: '5px', md: '0px 12px' },
                                              },
                                            }}
                                            label={itemTags}
                                          />
                                        </Box>
                                      ))}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        ) : (
                          <Box
                            sx={{
                              marginTop: 1,
                              padding: 1,
                              backgroundColor: `${theme.palette.divider}`,
                              fontSize: '1.4rem',
                              color: 'text.secondary',
                            }}
                          >
                            {t('cve.cveDetail.noReferences')}
                          </Box>
                        )}
                      </Stack>

                      {/*  Configurations */}
                      <Stack
                        sx={{
                          borderTop: `5px solid ${theme.palette.divider}`,
                          marginTop: 2,
                          backgroundColor: 'background.main',
                          padding: 2,
                          borderRadius: '3px',
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                        }}
                      >
                        <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                          {t('cve.cveDetail.configurations')}
                        </Typography>
                        {!_.isEmpty(cveDetail.configurations) ? (
                          <>
                            {cveDetail.configurations.map((item: any, index: number) => (
                              <>
                                <Box key={index} sx={{ paddingBlock: 1, width: '100%' }}>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ fontWeight: 700, marginTop: 1 }}
                                  >
                                    {t('cve.cveDetail.configuration')} {index + 1}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      marginTop: 0.5,
                                    }}
                                  >
                                    {item.operator && item.nodes.length > 1 && (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          backgroundColor: '#d2d6de',
                                          padding: 3,
                                          alignItems: 'center',
                                          maxWidth: '70px',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: '#161C24',
                                            fontWeight: 700,
                                          }}
                                        >
                                          {item.operator}
                                        </Typography>
                                      </Box>
                                    )}
                                    <Box
                                      sx={{
                                        marginLeft: item.operator === 'AND' ? 0.5 : 0,
                                        width: '100%',
                                      }}
                                    >
                                      {item.nodes.map((child: any, indexNode: number) => (
                                        <React.Fragment key={indexNode}>
                                          <Box>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                              }}
                                            >
                                              {child.cpeMatch.length > 1 && (
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    backgroundColor: '#d2d6de',
                                                    padding: 3,
                                                    alignItems: 'center',
                                                    marginTop: indexNode > 0 ? 0.5 : 0,
                                                    maxWidth: '70px',
                                                    justifyContent: 'center',
                                                  }}
                                                >
                                                  <Typography
                                                    variant="body2"
                                                    sx={{
                                                      color: '#161C24',
                                                      fontWeight: 700,
                                                    }}
                                                  >
                                                    {child.operator}
                                                  </Typography>
                                                </Box>
                                              )}

                                              <Box
                                                sx={{
                                                  marginLeft: child.operator === 'AND' ? 0.5 : 0,
                                                  width: '100%',
                                                  marginTop: indexNode > 0 && child.cpeMatch.length > 1 ? 0.5 : 0,
                                                }}
                                              >
                                                {child.cpeMatch.map((cpe: any, indexOperator: number) => (
                                                  <React.Fragment key={indexOperator}>
                                                    <Typography
                                                      variant="body2"
                                                      color="text.secondary"
                                                      sx={{
                                                        wordBreak: 'break-all',
                                                        border: `1px solid ${theme.palette.divider}`,
                                                        padding: 1,
                                                      }}
                                                    >
                                                      {cpe.criteria}
                                                    </Typography>
                                                  </React.Fragment>
                                                ))}
                                              </Box>
                                            </Box>
                                          </Box>
                                        </React.Fragment>
                                      ))}
                                    </Box>
                                  </Box>
                                </Box>
                                {index < cveDetail.configurations.length - 1 && <Divider sx={{ marginTop: 1 }} />}
                              </>
                            ))}
                          </>
                        ) : (
                          <Box
                            sx={{
                              marginTop: 1,
                              padding: 1,
                              backgroundColor: 'background.main',
                              fontSize: '1.4rem',
                              color: 'text.secondary',
                              boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                            }}
                          >
                            {t('cve.cveDetail.noConfigurations')}
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item md={2.4} xs={12} sx={{ marginTop: { md: 0, xs: '20px' }, width: '100%' }}>
                      <Stack
                        sx={{
                          backgroundColor: 'background.main',
                          padding: 2,
                          borderTop: `5px solid ${theme.palette.divider}`,
                          borderRadius: '3px',
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                        }}
                      >
                        <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                          {t('cve.cveDetail.information')}
                        </Typography>
                        <Box
                          sx={{ borderBottom: `1px solid ${theme.palette.divider}`, marginTop: 1, paddingBottom: 2 }}
                        >
                          <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                            >
                              {t('cve.cveDetail.published')}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
                              {formatTimeCVEDetail(cveDetail.published)}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                            >
                              {t('cve.cveDetail.updated')}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
                              {formatTimeCVEDetail(cveDetail.updated)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ borderBottom: `1px solid ${theme.palette.divider}`, paddingBottom: 2 }}>
                          <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                            >
                              <ShareIcon sx={{ verticalAlign: 'text-top', height: '2rem', mr: 1 }} />
                              {t('cve.cveDetail.nvdLink')}
                            </Typography>
                            <Link
                              href={`https://nvd.nist.gov/vuln/detail/${id}`}
                              target="_blank"
                              sx={{
                                marginLeft: 1,
                                color: 'info.main',
                                fontSize: '1.4rem',
                                '&:hover': {
                                  color: 'text.secondary',
                                  textDecoration: 'underline',
                                },
                              }}
                            >
                              {id}
                            </Link>
                          </Box>
                          <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                            >
                              <ShareIcon sx={{ verticalAlign: 'text-top', height: '2rem', mr: 1 }} />
                              {t('cve.cveDetail.mitreLink')}
                            </Typography>
                            <Link
                              href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${id}`}
                              target="_blank"
                              sx={{
                                marginLeft: 1,
                                color: 'info.main',
                                fontSize: '1.4rem',
                                '&:hover': {
                                  color: 'text.secondary',
                                  textDecoration: 'underline',
                                },
                              }}
                            >
                              {id}
                            </Link>
                          </Box>
                          <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                            >
                              <ShareIcon sx={{ verticalAlign: 'text-top', height: '2rem', mr: 1 }} />
                              {t('cve.cveDetail.cveLink')}
                            </Typography>
                            <Link
                              href={`https://www.cve.org/CVERecord?id=${id}`}
                              target="_blank"
                              sx={{
                                marginLeft: 1,
                                color: 'info.main',
                                fontSize: '1.4rem',
                                '&:hover': {
                                  color: 'text.secondary',
                                  textDecoration: 'underline',
                                },
                              }}
                            >
                              {id}
                            </Link>
                          </Box>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          backgroundColor: 'background.main',
                          padding: 2,
                          borderRadius: '3px',
                          marginTop: 2,
                          borderTop: `5px solid ${theme.palette.divider}`,
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                        }}
                      >
                        <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                          {t('cve.cveDetail.productsAffected')}
                        </Typography>
                        {!_.isEmpty(cveDetail.vendors) ? (
                          <Box sx={{ paddingBottom: 2 }}>
                            {Object.entries(cveDetail.vendors).map(([key, value]: [any, any]) => (
                              <Box key={key} sx={{ marginTop: 1.5 }}>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{ fontWeight: 700, wordBreak: 'break-word' }}
                                >
                                  {key}
                                </Typography>
                                {value.map((valueSub: any, index: number) => (
                                  <Box key={index}>
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      sx={{ marginLeft: 1, wordBreak: 'break-word' }}
                                    >
                                      <FiberManualRecordIcon sx={{ verticalAlign: 'middle', height: '0.6rem' }} />
                                      {valueSub}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              marginTop: 1,
                              padding: 1,
                              backgroundColor: 'background.main',
                              fontSize: '1.4rem',
                              color: 'text.secondary',
                              boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                            }}
                          >
                            {t('cve.cveDetail.noProduct')}
                          </Box>
                        )}
                      </Stack>
                      <Stack
                        sx={{
                          backgroundColor: 'background.main',
                          padding: 2,
                          borderRadius: '3px',
                          marginTop: 2,
                          borderTop: `5px solid ${theme.palette.divider}`,
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                        }}
                      >
                        <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                          CWE
                        </Typography>
                        {!_.isEmpty(cveDetail.cwes) ? (
                          <Box sx={{ marginTop: 1 }}>
                            {cveDetail.cwes.map((value: string, index: number) => (
                              <Link key={index} href={`/cve?cwe=${value}`} rel="noopener">
                                <Typography
                                  key={index}
                                  onClick={() => {
                                    navigate('/cve', { state: { cwe: value } });
                                  }}
                                  variant="body2"
                                  sx={{
                                    cursor: 'pointer',
                                    marginLeft: 1,
                                    color: 'info.main',
                                    '&:hover': {
                                      color: 'text.secondary',
                                      textDecoration: 'underline',
                                    },
                                  }}
                                >
                                  {value}
                                </Typography>
                              </Link>
                            ))}
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              marginTop: 1,
                              padding: 1,
                              backgroundColor: 'background.main',
                              fontSize: '1.4rem',
                              color: 'text.secondary',
                              boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                            }}
                          >
                            {t('cve.cveDetail.noCwe')}
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid container sx={{ justifyContent: 'space-between' }}>
                    <Grid item md={9.3} xs={12}>
                      <Stack
                        sx={{
                          borderTop: `5px solid ${theme.palette.divider}`,
                          backgroundColor: 'background.main',
                          padding: 2,
                          borderRadius: '3px',
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                        }}
                      >
                        <Skeleton width="50%" height={30} />
                        <Skeleton width="80%" height={30} />
                      </Stack>
                    </Grid>
                    <Grid item md={2.4} xs={12} sx={{ mt: { xs: 2, md: 0 } }}>
                      <Stack
                        sx={{
                          backgroundColor: 'background.main',
                          padding: 2,
                          borderRadius: '3px',
                          borderTop: `5px solid ${theme.palette.divider}`,
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                        }}
                      >
                        <Skeleton width="100%" height={30} />
                        <Skeleton width="100%" height={30} />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};
export default CVEDetails;
