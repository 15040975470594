import { useState, useEffect } from 'react';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { AppBar, Typography, Box, Collapse, List } from '@mui/material';
import * as _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialContents = [
    {
      name: 'Score',
      label: 'Score',
      icon: <AutoGraphIcon />,
      src: '/score',
      active: true,
    },
    {
      name: 'asset',
      label: 'Asset',
      icon: <StorageIcon />,
      src: '/asset',
      active: false,
    },
    {
      name: 'Information',
      label: 'Information',
      icon: <PublicIcon />,
      src: '/info',
      active: false,
    },
    {
      name: 'Subdomains',
      label: 'Subdomains',
      icon: <ViewModuleIcon />,
      src: '/subdomains',
      active: false,
    },
    {
      name: 'ScanVul',
      label: 'Vulnerability',
      icon: <SecurityIcon />,
      src: '/vul',
      active: false,
    },
    {
      name: 'Spider',
      label: 'Spider',
      icon: <AccountTreeIcon />,
      src: '/spider',
      active: false,
    },
    {
      name: 'report',
      label: 'Report',
      icon: <AssessmentIcon />,
      src: '/report',
      active: false,
    },
  ];

  const initialContentsOsint = [
    {
      name: 'urlscan',
      label: 'Urlscan',
      src: '/osint/urlscan',
      active: true,
    },
    {
      name: 'fofa',
      label: 'Fofa',
      src: '/osint/fofa',
      active: false,
    },
    {
      name: 'theharvester',
      label: 'Theharvester',
      src: '/osint/theharvester',
      active: false,
    },
    {
      name: 'whois',
      label: 'Whois',
      src: '/osint/whois',
      active: false,
    },
    {
      name: 'mailhunter',
      label: 'Mailhunter',
      src: '/osint/mailhunter',
      active: false,
    },
  ];
  const [contents, setContents] = useState(initialContents);
  const [contentsOsint, setContentsOsint] = useState(initialContentsOsint);
  const [openOsint, setOpenOsint] = useState(false);

  const handleClickOsint = () => {
    setOpenOsint(!openOsint);
  };

  const handleContentClick = (contentSrc: string) => {
    navigate(contentSrc);
  };

  const handleOsintClick = (contentSrc: string) => {
    const currentParams = location.search;
    navigate(`${contentSrc}${currentParams}`);
  };

  useEffect(() => {
    const updatedContents = contents.map((content) =>
      location.pathname === content.src ? { ...content, active: true } : { ...content, active: false },
    );
    setContents(updatedContents);

    const updatedContentsOsint = contentsOsint.map((content) =>
      location.pathname === content.src ? { ...content, active: true } : { ...content, active: false },
    );
    setContentsOsint(updatedContentsOsint);

    if (location.pathname.includes('osint/')) {
      setOpenOsint(true);
    }
  }, [location.pathname]);

  return (
    <>
      <AppBar
        elevation={0}
        sx={{
          width: '140px',
          position: 'fixed',
          left: 0,
          top: '60px',
          bottom: 0,
          backgroundColor: 'background.main',
          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
          padding: 1,
        }}
      >
        {contents.map((content) => (
          <Box
            key={content.name}
            onClick={() => {
              handleContentClick(content.src);
            }}
            sx={{
              mt: 0.5,
              padding: '3px 10px',
              backgroundColor: content.active ? 'rgba(3, 201, 215, 0.5)' : 'initial',
              borderRadius: 0.5,
              transition: 'background-color 0.4s ease, color 0.4s ease',
              width: '100%',
              justifyContent: 'center',
              '& .MuiTypography-root': {
                color: content.active ? 'common.white' : 'text.secondary',
              },
              '&:hover': {
                cursor: 'pointer',
                background: content.active
                  ? 'rgba(3, 201, 215, 0.5)'
                  : (theme: any) => theme.palette.background.hoverMenu,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: '2rem', color: content.active ? 'common.white' : 'text.secondary' }}>
                {content.icon}
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.2rem',
                  ml: 1.5,
                  whiteSpace: 'nowrap',
                }}
              >
                {content.label}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box
          onClick={() => {
            handleClickOsint();
          }}
          sx={{
            mt: 0.5,
            padding: '3px 10px',
            backgroundColor: location.pathname.includes('osint/') ? 'rgba(3, 201, 215,0.5)' : 'initial',
            borderRadius: 0.5,
            transition: 'background-color 0.4s ease, color 0.4s ease',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .MuiTypography-root': {
              color: openOsint ? 'common.white' : 'text.secondary',
            },
            '& .MuiSvgIcon-root': {
              color: openOsint ? 'common.white' : 'text.secondary',
            },
            '&:hover': {
              cursor: 'pointer',
              background: location.pathname.includes('osint/')
                ? 'rgba(3, 201, 215, 0.5)'
                : (theme: any) => theme.palette.background.hoverMenu,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontSize: '2rem' }}>
              <TravelExploreIcon />
            </Typography>
            <Typography
              sx={{
                fontSize: '1.2rem',
                ml: 1.5,
                whiteSpace: 'nowrap',
              }}
            >
              OSINT
            </Typography>
          </Box>
          {openOsint ? <ExpandLess /> : <ExpandMore />}
        </Box>
        <Collapse in={openOsint} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {contentsOsint.map((content) => (
              <Box
                key={content.name}
                onClick={() => {
                  handleOsintClick(content.src);
                }}
                sx={{
                  ml: 2,
                  mt: 0.5,
                  padding: '5px 0px',
                  backgroundColor: content.active ? 'action.hover' : 'initial',
                  borderRadius: 0.5,
                  transition: 'background-color 0.4s ease, color 0.4s ease',
                  justifyContent: 'center',
                  '& .MuiTypography-root': {
                    color: content.active ? '#E9B02F' : 'text.secondary',
                  },

                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    ml: 1.5,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {content.label}
                </Typography>
              </Box>
            ))}
          </List>
        </Collapse>
      </AppBar>
    </>
  );
};
export default SideBar;
