import RemoveIcon from '@mui/icons-material/Remove';
import {
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Pagination,
  Box,
  Chip,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useCommonInfo } from '@/contexts/Common';
import { CVEType, VendorType } from '@/types/Assets';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';

import TableProduct from './TableProduct';

interface TableAssetProps {
  listVendors: VendorType[];
  onSearchValueChange: (newValue: string) => void;
  onPageChange: (newValue: number) => void;
  page: number;
  searchValue?: string;
  handleFilterAsset?: (searchKey: undefined, pageValue: number) => void;
  handleGetListAsset: () => void;
  groupId: number;
  totalVendor: number;
}

const TableAsset = (props: TableAssetProps) => {
  const {
    listVendors,
    onSearchValueChange,
    onPageChange,
    page,
    searchValue,
    handleFilterAsset,
    handleGetListAsset,
    groupId,
    totalVendor,
  } = props;

  const { isLoading } = useCommonInfo();
  const { t } = useTranslation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const handleChangePage = (pageValue: number) => {
    onPageChange(pageValue);
    if (searchValue && inforUser.role !== 'super admin') {
      handleFilterAsset?.(undefined, pageValue);
    }
  };

  const CvssLabel = (bgColor: string, value: number, label: string) => {
    return (
      <Chip
        sx={{ backgroundColor: bgColor, height: '22px', color: '#fff', fontWeight: 600, fontSize: '1rem' }}
        label={`${value} ${label}`}
      />
    );
  };

  const DisplayCvssV3 = (cvssScore: { cvss: number }) => {
    const value: number = Object.values(cvssScore)[0];

    if (value >= 0.1 && value <= 3.9) {
      return CvssLabel('#00c0ef', value, t('cve.cvss.low'));
    }
    if (value >= 4.0 && value <= 6.9) {
      return CvssLabel('#f39c12', value, t('cve.cvss.medium'));
    }
    if (value >= 7.0 && value <= 8.9) {
      return CvssLabel('#dd4b39', value, t('cve.cvss.high'));
    }
    if (value >= 9.0 && value <= 10.0) {
      return CvssLabel('#972b1e', value, t('cve.cvss.critical'));
    }
    return (
      <Chip
        sx={{
          backgroundColor: '#d2d6de',
          height: '22px',
          color: '#161C24',
          fontWeight: 600,
          fontSize: '1rem',
        }}
        label="N/A"
      />
    );
  };

  return (
    <>
      <TableContainer sx={{ boxShadow: 2, padding: 2, borderRadius: 1, backgroundColor: 'action.hover' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'action.hover' }}>
              <TableCell sx={{ width: '15%' }}>{t('table.asset.vendor')}</TableCell>
              <TableCell align="center" sx={{ width: '35%' }}>
                {t('table.asset.product')}
              </TableCell>
              <TableCell align="center" sx={{ width: '35%' }}>
                {t('table.asset.vulnerability')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? (
              <>
                {!_.isEmpty(listVendors) ? (
                  <>
                    {listVendors.map((row: VendorType) => (
                      <TableRow
                        key={row.vendor}
                        hover
                        tabIndex={-1}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>
                          <Link
                            href={`/cve?vendor=${row.vendor}`}
                            rel="noopener"
                            underline="none"
                            sx={{
                              color: 'primary.main',
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'primary.dark',
                              },
                            }}
                          >
                            {row.vendor_label}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          {!_.isEmpty(row.list_product) ? (
                            <TableProduct
                              listVendors={row}
                              onSearchValueChange={onSearchValueChange}
                              handleGetListAsset={handleGetListAsset}
                              groupId={groupId}
                            />
                          ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <RemoveIcon />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          {!_.isEmpty(row.list_cve) ? (
                            <>
                              <TableContainer sx={{ maxHeight: '340px', overflow: 'auto' }}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        width="35%"
                                        sx={{ whiteSpace: 'nowrap', padding: '10px', fontSize: '1.2rem' }}
                                      >
                                        {`${t('table.asset.vulnerability')} (${row.list_cve.length})`}
                                      </TableCell>
                                      <TableCell width="35%" sx={{ fontSize: '1.2rem', padding: '10px' }}>
                                        CVSS v3.1
                                      </TableCell>
                                      <TableCell
                                        width="30%"
                                        sx={{ whiteSpace: 'nowrap', padding: '10px', fontSize: '1.2rem' }}
                                      >
                                        {t('table.asset.update')}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row.list_cve.map((cve: CVEType) => (
                                      <TableRow key={cve.id} hover>
                                        <TableCell sx={{ padding: '10px', fontSize: '1.2rem' }}>
                                          <Link
                                            underline="none"
                                            sx={{
                                              padding: 0.5,
                                              color: 'primary.main',
                                              cursor: 'pointer',
                                              '&:hover': {
                                                color: 'primary.dark',
                                              },
                                            }}
                                            href={`/cve/${cve.id}`}
                                            rel="noopener noreferrer"
                                          >
                                            {cve.id}
                                          </Link>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px' }}>
                                          <DisplayCvssV3 cvss={cve.cvssv3} />
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px', fontSize: '1.2rem' }}>
                                          {cve.updated}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <RemoveIcon />
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                      {t('notify.noData')}
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell>
                  <Skeleton height={20} width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="80%" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalVendor / PAGE_SIZE)}
            color="primary"
            onChange={(event, value) => {
              handleChangePage(value);
            }}
            defaultPage={page}
          />
        </Box>
      </TableContainer>
    </>
  );
};
export default TableAsset;
