/* eslint-disable @typescript-eslint/comma-dangle, @typescript-eslint/quotes, prefer-destructuring */
import React, { useState, useCallback } from 'react';

import {
  Box,
  Typography,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
  SelectChangeEvent,
} from '@mui/material';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import MUIDataTable from 'mui-datatables';
import { useDropzone, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

import useAsm from '@/Hooks/fetchApi/useAsm';

interface FileData {
  file_name: string;
  stats: Record<string, number>;
  threat_class?: {
    popular_threat_category?: Array<{
      value: string;
      count: number;
    }>;
  };
  analysis_result?: AnalysisArray;
}

interface AnalysisResult {
  category: string;
  engine_name: string;
  engine_update: string;
  engine_version: string | null;
  method: string;
  result: string | null;
}

interface AnalysisArray {
  [key: string]: AnalysisResult | undefined;
}

interface FileStatus {
  file: File;
  status: 'pending' | 'uploading' | 'success' | 'error';
  progress: number;
}

function convertAnalysisToTableData(analysisArray: AnalysisArray): any[] {
  return Object.entries(analysisArray).map(([key, value]) => {
    if (value === undefined) {
      return {
        key,
        category: '',
        engine_name: '',
        engine_update: '',
        engine_version: '',
        method: '',
        result: '',
      };
    }

    return {
      key,
      ...value,
    };
  });
}

const MalwarePage: React.FC = () => {
  const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
  const [fileData, setFileData] = useState<FileData[] | null>(null);
  const [status, setStatus] = useState<string>('');
  const [selectedFileName, setSelectedFileName] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { handleMalware } = useAsm();

  const columns = [
    {
      name: 'engine_name',
      label: t('malware_scan.engine_name'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'category',
      label: t('malware_scan.category'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'engine_update',
      label: t('malware_scan.engine_update'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'engine_version',
      label: t('malware_scan.engine_version'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string | null) => value || 'N/A',
      },
    },
    {
      name: 'method',
      label: t('malware_scan.method'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'result',
      label: t('malware_scan.table_result'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string | null) => value || 'N/A',
      },
    },
  ];

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setFileStatuses((prevStatuses) => [
      ...prevStatuses,
      ...acceptedFiles.map(
        (file): FileStatus => ({
          file,
          status: 'pending',
          progress: 0,
        }),
      ),
    ]);

    if (fileRejections.length > 0) {
      setStatus(`${fileRejections.length} file(s) ${t('rejected_file')}`);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true, // Allow multiple file selection
  });

  const uploadFiles = async () => {
    if (fileStatuses.length === 0) {
      setStatus(t('malware_scan.no_file_warning'));
      return;
    }

    setStatus(t('malware_scan.uploading'));
    setIsUploading(true);

    const formData = new FormData();
    fileStatuses.forEach((fileStatus) => {
      formData.append('files', fileStatus.file, fileStatus.file.name);
    });

    try {
      const response = await handleMalware(formData);

      const data = response;
      setFileData(data);
      if (data && data.length > 0) {
        setSelectedFileName(data[0].file_name);
      }
      setFileStatuses((prev) => prev.map((fs) => ({ ...fs, status: 'success', progress: 100 })));
      setStatus(t('malware_scan.analysis_complete'));
    } catch (error) {
      setFileStatuses((prev) => prev.map((fs) => ({ ...fs, status: 'error', progress: 0 })));
      setStatus(`${t('malware_scan.analysis_failed')} ${axios.isAxiosError(error) ? error.message : 'Unknown error'}`);
    } finally {
      setIsUploading(false);
      setStatus('');
      setFileStatuses([]);
    }
  };

  const handleFileSelect = (event: SelectChangeEvent<string>) => {
    setSelectedFileName(event.target.value as string);
  };

  const renderFileStatuses = () => {
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Files to upload:</Typography>
        <ul>
          {fileStatuses.map((fileStatus, index) => (
            <li key={index}>
              {fileStatus.file.name} - {fileStatus.status}
              {fileStatus.status === 'uploading' && (
                <LinearProgress variant="determinate" value={fileStatus.progress} />
              )}
            </li>
          ))}
        </ul>
      </Box>
    );
  };

  const renderFileSelector = () => {
    if (!fileData || fileData.length === 0) return null;

    return (
      <FormControl sx={{ mt: 2, mb: 2 }}>
        <InputLabel id="file-select-label">Select File</InputLabel>
        <Select
          labelId="file-select-label"
          id="file-select"
          value={selectedFileName}
          label="Select File"
          onChange={handleFileSelect}
        >
          {fileData.map((file) => (
            <MenuItem key={file.file_name} value={file.file_name}>
              {file.file_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderCharts = () => {
    if (!fileData || fileData.length === 0 || !selectedFileName) return null;

    const selectedFile = fileData.find((file) => file.file_name === selectedFileName);
    if (!selectedFile) return null;

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#5030c2', '#30c2b8'];

    return (
      <Grid container spacing={2} sx={{ mt: 2, justifyContent: 'space-between', paddingLeft: '8px' }}>
        <Grid item xs={12}>
          <Typography variant="h6">{selectedFile.file_name}</Typography>
        </Grid>
        {selectedFile.threat_class?.popular_threat_category && (
          <Grid item xs={6}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={selectedFile.threat_class.popular_threat_category}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="value" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        )}
        <Grid item xs={6}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={Object.entries(selectedFile.stats).map(([name, value]) => ({ name, value }))}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {Object.entries(selectedFile.stats).map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    );
  };

  const renderTable = () => {
    if (!fileData || fileData.length === 0 || !selectedFileName) return null;

    const selectedFile = fileData.find((file) => file.file_name === selectedFileName);
    if (!selectedFile) return null;

    return (
      <Box sx={{ marginX: '8px', margin: '10px', width: '100%' }}>
        <Typography variant="h6">{selectedFile.file_name}</Typography>
        <MUIDataTable
          data={convertAnalysisToTableData(selectedFile.analysis_result || {})}
          columns={columns}
          title={t('malware_scan.analysis_result')}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ margin: '8px', width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        {t('malware_scan.title')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box
          {...getRootProps()}
          sx={{
            border: '2px dashed #cccccc',
            borderRadius: '4px',
            padding: '20px',
            textAlign: 'center',
            color: '#cccccc',
            cursor: 'pointer',
            backgroundColor: isDragActive ? '#e6e6e6' : 'white',
            marginBottom: '20px',
            width: '60%',
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>{t('malware_scan.drag_dropzone')}</Typography>
          ) : (
            <Typography>{t('malware_scan.nodrag_dropzone')}</Typography>
          )}
        </Box>
        <Button variant="contained" onClick={uploadFiles} disabled={fileStatuses.length === 0 || isUploading}>
          {isUploading ? t('malware_scan.uploading') : t('malware_scan.upload_complete')}
        </Button>
      </Box>
      {fileStatuses.length > 0 && renderFileStatuses()}
      {status && <Typography sx={{ mt: 2 }}>{status}</Typography>}
      {fileData && fileData.length > 0 && (
        <>
          {renderFileSelector()}
          {renderCharts()}
          {renderTable()}
        </>
      )}
    </Box>
  );
};

export default MalwarePage;
/* eslint-enable @typescript-eslint/comma-dangle, @typescript-eslint/quotes */
