import { blue, common, green, grey, lightBlue, orange, purple, red } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';

const PRIMARY_LIGHT = {
  main: blue[700],
  light: blue[400],
  dark: blue[800],
};

const PRIMARY_DARK = {
  main: blue[200],
  light: blue[50],
  dark: blue[400],
};

const SECONDARY_LIGHT = {
  main: purple[500],
  light: purple[300],
  dark: purple[700],
};

const SECONDARY_DARK = {
  main: purple[200],
  light: purple[50],
  dark: purple[400],
};

const INFO_LIGHT = {
  main: lightBlue[700],
  light: lightBlue[500],
  dark: lightBlue[900],
};

const INFO_DARK = {
  main: lightBlue[400],
  light: lightBlue[300],
  dark: lightBlue[700],
};

const ERROR_LIGHT = {
  main: red[700],
  light: red[400],
  dark: red[800],
};

const ERROR_DARK = {
  main: red[500],
  light: red[300],
  dark: red[700],
};

const SUCCESS_LIGHT = {
  main: green[800],
  light: green[500],
  dark: green[900],
};

const SUCCESS_DARK = {
  main: green[400],
  light: green[300],
  dark: green[700],
};

const WARNING_LIGHT = {
  main: '#ed6c02', // closest to orange[800] that pass 3:1.
  light: orange[500],
  dark: orange[900],
};

const WARNING_DARK = {
  main: orange[400],
  light: orange[300],
  dark: orange[700],
};

// const GRADIENTS = {
//   primary: createGradient(PRIMARY.light, PRIMARY.main),
//   info: createGradient(INFO.light, INFO.main),
//   success: createGradient(SUCCESS.light, SUCCESS.main),
//   warning: createGradient(WARNING.light, WARNING.main),
//   error: createGradient(ERROR.light, ERROR.main),
// };

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON_LIGHT = {
  common: { black: common.black, white: common.white, gray: '#E5E5E5' },
  primary: { ...PRIMARY_LIGHT },
  secondary: { ...SECONDARY_LIGHT },
  info: { ...INFO_LIGHT },
  success: { ...SUCCESS_LIGHT },
  warning: { ...WARNING_LIGHT },
  error: { ...ERROR_LIGHT },
  chart: CHART_COLORS,
  text: {
    primary: alpha(common.black, 0.87),
    secondary: alpha(common.black, 0.6),
    disabled: alpha(common.black, 0.38),
  },
  divider: alpha(common.black, 0.12),
  background: {
    paper: '#fafbfb',
    default: '#fafbfb',
    main: 'rgb(250, 251, 251)',
    dark: 'rgb(250, 251, 251)',
    menu: '#ededed',
    hoverMenu: 'rgb(229, 250, 251)',
    bgFooterUp: '#fafbfb',
    bgFooter: '#ededed',
    bgHeaderTable: grey[300],
  },
  action: {
    active: alpha(common.black, 0.54),
    hover: alpha(common.black, 0.04),
    selected: alpha(common.black, 0.08),
    disabled: alpha(common.black, 0.26),
    disabledBackground: alpha(common.black, 0.12),
    focus: alpha(common.black, 0.12),
    hoverOpacity: 0.04,
    selectedOpacity: 0.08,
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
    disabledOpacity: 0.38,
  },
  scrollbar: {
    background: grey[300],
    thumb: grey[400],
    hover: grey[500],
    active: grey[600],
  },
};

const COMMON_DARK = {
  common: { black: common.black, white: common.white, gray: '#E5E5E5' },
  primary: { ...PRIMARY_DARK },
  secondary: { ...SECONDARY_DARK },
  info: { ...INFO_DARK },
  success: { ...SUCCESS_DARK },
  warning: { ...WARNING_DARK },
  error: { ...ERROR_DARK },
  chart: CHART_COLORS,
  text: {
    primary: common.white,
    secondary: alpha(common.white, 0.7),
    disabled: alpha(common.white, 0.5),
    title: '#adb0bb',
  },
  divider: alpha(common.white, 0.12),
  background: {
    paper: '#121212',
    default: '#121212',
    main: 'rgb(40, 44, 52)',
    dark: 'rgb(23, 28, 35)',
    menu: '#313d42',
    hoverMenu: 'rgb(8, 46, 69)',
    bgFooterUp: 'rgb(16 20 54)',
    bgFooter: 'rgb(13 16 45)',
    bgHeaderTable: '#3d3d3d',
  },
  action: {
    active: common.white,
    hover: alpha(common.white, 0.08),
    selected: alpha(common.white, 0.16),
    disabled: alpha(common.white, 0.3),
    disabledBackground: alpha(common.white, 0.12),
    focus: alpha(common.white, 0.12),
    hoverOpacity: 0.08,
    selectedOpacity: 0.16,
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
    disabledOpacity: 0.38,
  },
  scrollbar: {
    background: grey[700],
    thumb: grey[500],
    hover: grey[400],
    active: grey[300],
  },
};

const palette = {
  light: {
    ...COMMON_LIGHT,
  },
  dark: {
    ...COMMON_DARK,
  },
};

export default palette;
