import { useEffect, useState } from 'react';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import GroupsIcon from '@mui/icons-material/Groups';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Box, Card, CardContent, CardMedia, Grid, Pagination, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import useManagenent from '@/Hooks/fetchApi/useManagenent';
import { Course } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

import DetailCourse from './DetailCourse';

const Learning = () => {
  const { handleGetListCourses } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listCourses, setListCourses] = useState<Course[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [courseSelected, setCourseSelected] = useState<Course>();
  const [videoDurations, setVideoDurations] = useState<{ [key: string]: string }>({});

  const handleGetCourses = async () => {
    const params = {
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };
    const dataRes = await handleGetListCourses(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_course, total_courses } = dataRes;
    setListCourses(list_course);
    setTotalPage(total_courses);

    // Lấy thời lượng cho tất cả các video trong danh sách khóa học
    list_course.forEach((course: Course) => {
      const videoElement = document.createElement('video');
      videoElement.src = course.link_video;
      videoElement.addEventListener('loadedmetadata', () => {
        const { duration } = videoElement;
        setVideoDurations((prevDurations) => ({
          ...prevDurations,
          [course.id]: `${Math.floor(duration / 60)}:${Math.floor(duration % 60)
            .toString()
            .padStart(2, '0')}`,
        }));
      });
    });
  };

  useEffect(() => {
    handleGetCourses();
  }, [searchParams]);

  const handleChangePage = (pageValue: number) => {
    setSearchParams({ page: pageValue.toString() });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        Khóa học miễn phí
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {listCourses.map((course: Course) => (
          <Grid item xs={12} sm={6} md={3} lg={2.4} key={course.id}>
            <Card
              sx={{
                borderRadius: 2,
                transition: 'all 0.2s',
                backgroundColor: 'background.main',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 4px 8px #0000001a',
                },
              }}
              onClick={() => {
                setCourseSelected(course);
                setOpenDialogDetail(true);
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <CardMedia component="img" alt="green iguana" image={course.thumbnail} sx={{ height: '250px' }} />
                <Box
                  sx={{
                    position: 'absolute',
                    left: 0,
                    bottom: '60px',
                    padding: { xs: 2, sm: 3 },
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <PlayCircleFilledIcon
                    sx={{
                      fontSize: '7rem',
                      cursor: 'pointer',
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'error.main',
                      },
                    }}
                  />
                </Box>
              </Box>

              <CardContent>
                <Typography
                  gutterBottom
                  component="div"
                  sx={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  {course.title}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                    <GroupsIcon sx={{ fontSize: '2.2rem' }} />
                    <Typography sx={{ ml: 1, alignSelf: 'center' }}>9999</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                    <AccessTimeFilledIcon sx={{ fontSize: '2.2rem' }} />
                    <Typography sx={{ ml: 1, alignSelf: 'center' }}>
                      {videoDurations[course.id] || 'Loading...'}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Pagination
          count={Math.ceil(totalPage / PAGE_SIZE)}
          color="primary"
          onChange={(event, value) => {
            handleChangePage(value);
          }}
          page={Number(searchParams.get('page') || 1)}
        />
      </Box>
      {courseSelected && (
        <DetailCourse
          openDialogDetail={openDialogDetail}
          onDialogDetailChange={setOpenDialogDetail}
          detail={courseSelected}
        />
      )}
    </Box>
  );
};

export default Learning;
