import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupsIcon from '@mui/icons-material/Groups';
import {
  Box,
  Typography,
  InputBase,
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  Tooltip,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  useTheme,
  Avatar,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import InputAdornment from '@mui/material/InputAdornment';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import UpdateButton from '@/components/common/Button/UpdateButton';
import { formatTimeCVEDetail } from '@/components/common/format/FormatTimeCVE';
import { Transition } from '@/components/common/Transition';
import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useNotify from '@/Hooks/common/useNotify';
import useAuth from '@/Hooks/fetchApi/useAuth';
import { UserType } from '@/types/management';
import { decrypt } from '@/utils/crypto';

interface UserManagementProps {
  listUsers: UserType[];
  setIsAddUser: (newValue: boolean) => void;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}

const UserManagement: React.FC<UserManagementProps> = ({
  listUsers,
  setIsAddUser,
  handleGetUser,
  handleGetCompany,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { onNotify } = useNotify();
  const { handleEditGroup, handleDeleteGroup, handleChangePermision, handleDeleteUser } = useAuth();

  const [filterUser, setFilterUser] = useState<UserType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({ nameGroup: '', expireDate: '', idGroup: 0 });
  const [groupNameDelete, setGroupNameDelete] = useState('');
  const [isUpadatePermission, setIsUpadatePermission] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState('');
  const [selectedUser, setSelectedUser] = useState({ username: '', role: '' });
  const [openDialogDeleteAll, setOpenDialogDeleteAll] = useState(false);
  const [selected, setSelected] = useState<readonly { user_id: string }[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    const filteredUsers = listUsers.flatMap((group: any) =>
      group.filter((user: UserType) => user?.user_name.toLowerCase().includes(event.target.value)),
    );
    setFilterUser(filteredUsers);

    if (event.target.value === '') {
      setFilterUser([]);
    }
  };

  const navigateEditSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setOpenDialogEdit(false);
    setSelectedGroup({ nameGroup: '', expireDate: '', idGroup: 0 });
  };

  const validationSchema = yup.object().shape({
    expireDate: yup.string().required(t('validation.fieldRequired')),
    groupName: yup.string().required(t('validation.fieldRequired')),
  });

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    if (data.expireDate !== selectedGroup.expireDate || data.groupName.trim() !== selectedGroup.nameGroup) {
      handleEditGroup(
        { expire_date_license: data.expireDate, group_name: data.groupName.trim(), group_id: selectedGroup.idGroup },
        navigateEditSuccess,
      );
    } else {
      onNotify('warning', t('notify.noValueChanged'), 3000);
    }
  };

  const navigateDeleteSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setOpenDialogDelete(false);
  };

  const navigateChangePermissionSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setIsUpadatePermission(false);
  };

  const handleGroupDelete = () => {
    const params = {
      group_name: groupNameDelete,
    };
    handleDeleteGroup(params, navigateDeleteSuccess);
  };

  const handleUpdateRole = (event: any) => {
    setSelectedPermission(event.target.value);
  };

  const handleUpdatePermission = () => {
    if (selectedPermission === '') {
      setIsUpadatePermission(false);
    } else {
      handleChangePermision(
        { user_name: selectedUser.username, permission: selectedPermission },
        navigateChangePermissionSuccess,
      );
    }
  };

  const deleteSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setOpenDialogDeleteAll(false);
    setSearchValue('');
    setSelected([]);
  };

  const handleDeleteAll = () => {
    const userIds = selected.map((item) => item.user_id);
    handleDeleteUser({ list_user_delete: userIds }, deleteSuccess);
  };

  interface EnhancedProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
  }

  const EnhancedHead = (props: EnhancedProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Checkbox
          color="primary"
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{
            'aria-label': 'select all desserts',
          }}
        />

        {numSelected > 0 && (
          <Button
            variant="contained"
            type="button"
            color="error"
            onClick={() => {
              setOpenDialogDeleteAll(true);
            }}
            sx={{
              width: '40px',
              textTransform: 'none',
              fontSize: '1.2rem',
              color: 'common.white',
              whiteSpace: 'nowrap',
            }}
          >
            {t('action.delete')}
          </Button>
        )}

        <ConfirmDeleteModal
          openDialogDelete={openDialogDeleteAll}
          setOpenDialogDelete={setOpenDialogDeleteAll}
          handleDelete={handleDeleteAll}
        />
      </Box>
    );
  };

  const UserInfo = (item: any) => {
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = item
          .filter((n: any) => n.permission_name !== 'manager')
          .map((n: any) => ({
            user_id: n.user_id,
          }));
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, user_id: string) => {
      const selectedIndex = selected.findIndex((itemSelected: any) => itemSelected.user_id === user_id);
      let newSelected: readonly { user_id: string }[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, { user_id });
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    };

    const isSelected = (user_id: string) =>
      selected.findIndex((itemSelected: any) => itemSelected.user_id === user_id) !== -1;

    const itemWithoutManager = item?.filter((n: any) => n.permission_name !== 'manager');

    return (
      <>
        {inforUser.role === 'manager' && (
          <EnhancedHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={itemWithoutManager?.length}
          />
        )}

        <Grid container sx={{ justifyContent: 'space-between' }}>
          {item?.map((itemUser: UserType, index: number) => (
            <Grid
              item
              xs={12}
              md={5.8}
              key={index}
              sx={{
                borderRadius: 2,
                mt: 2,
                padding: 2,
                backgroundColor: 'action.hover',
                boxShadow: 3,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary', alignSelf: 'center' }}>
                    {t('management.asm.groups.status')}
                  </Typography>
                  {itemUser?.is_active ? (
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#02bb9f',
                        backgroundColor: 'rgba(2, 187, 159, 0.1)',
                        ml: 1,
                        borderRadius: '5px',
                        padding: '2px 5px',
                        alignSelf: 'center',
                      }}
                    >
                      {t('management.asm.groups.active')}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#f9806c',
                        backgroundColor: '#ffffff1a',
                        ml: 1,
                        borderRadius: '5px',
                        padding: '2px 5px',
                      }}
                    >
                      {t('management.asm.groups.inactive')}
                    </Typography>
                  )}
                </Box>
                {itemUser.permission_name !== 'manager' && inforUser.role === 'manager' && (
                  <Box>
                    <Checkbox
                      color="primary"
                      role="checkbox"
                      onClick={(event) => handleClick(event, itemUser.user_id)}
                      checked={isSelected(itemUser.user_id)}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Box sx={{ width: '35%' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    E-mail
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                    {itemUser?.user_name}
                  </Typography>
                </Box>
                <Box sx={{ width: '35%' }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      {t('role.role')}
                    </Typography>
                    {inforUser.role === 'manager' && itemUser?.permission_name !== 'manager' && (
                      <Tooltip title={t('tooltip.update')} placement="right">
                        <EditIcon
                          onClick={() => {
                            setIsUpadatePermission(true);
                            setSelectedUser({ username: itemUser?.user_name, role: itemUser?.permission_name });
                          }}
                          sx={{
                            fontSize: '2rem',
                            marginLeft: 1.5,
                            cursor: 'pointer',
                            '&:hover': {
                              color: 'info.main',
                            },
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>

                  <Typography
                    variant="body2"
                    sx={{ color: 'text.primary', fontWeight: 500, textTransform: 'capitalize' }}
                  >
                    {itemUser?.permission_name}
                  </Typography>

                  <Dialog
                    open={isUpadatePermission}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                      setIsUpadatePermission(false);
                    }}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                      '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                          width: '100%',
                          maxWidth: '400px',
                          backgroundColor: 'background.dark',
                          border: `1px solid ${theme.palette.divider}`,
                        },
                      },
                    }}
                    BackdropProps={{
                      style: {
                        opacity: 0.2,
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <DialogTitle>
                      <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>{t('dialog.updatePermission')}</Typography>
                    </DialogTitle>
                    <DialogContent>
                      <Typography fontSize={14}>{selectedUser.username}</Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                        <FormControl fullWidth>
                          <Select
                            value={selectedPermission || selectedUser.role}
                            onChange={handleUpdateRole}
                            label=""
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  border: `1px solid ${theme.palette.divider}`,
                                  boxShadow: 15,
                                },
                              },
                            }}
                          >
                            <MenuItem sx={{ fontSize: '1.4rem' }} value="admin">
                              {t('role.admin')}
                            </MenuItem>
                            <MenuItem sx={{ fontSize: '1.4rem' }} value="member">
                              {t('role.member')}
                            </MenuItem>
                          </Select>
                        </FormControl>

                        <UpdateButton
                          handleClick={() => {
                            handleUpdatePermission();
                          }}
                        />
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Box sx={{ width: '35%' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {t('management.asm.groups.groupName')}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                    {itemUser?.group_name}
                  </Typography>
                </Box>
                <Box sx={{ width: '35%' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {t('management.asm.groups.expireDate')}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                    {formatTimeCVEDetail(itemUser?.expire_date_license)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Box
          sx={{
            width: '100%',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
          }}
        >
          <InputBase
            sx={{
              width: '100%',
              ml: 1,
              flex: 1,
              '.MuiInputBase-input': {
                padding: '10px 10px',
              },
            }}
            placeholder={t('placeholder.typeSearch')}
            inputProps={{
              'aria-label': 'filter company',
            }}
            endAdornment={
              searchValue && (
                <ClearIcon
                  onClick={() => {
                    setSearchValue('');
                    setFilterUser([]);
                  }}
                  sx={{
                    mr: 3,
                    color: 'text.secondary',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                />
              )
            }
            value={searchValue}
            onChange={handleInputChange}
          />
        </Box>
        {inforUser.role !== 'member' && (
          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setIsAddUser(true);
            }}
            sx={{
              width: 'fit-content',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('action.addUser')}
          </Button>
        )}
      </Box>
      <Box>
        {searchValue ? (
          <>
            {!_.isEmpty(filterUser) ? (
              <>{UserInfo(filterUser)}</>
            ) : (
              <Box sx={{ width: '100%', borderRadius: 2, mt: 3, padding: 2, backgroundColor: 'action.hover' }}>
                <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  {t('notify.noResult')}
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <>
            {inforUser.role !== 'super admin' ? (
              <>{UserInfo(listUsers[0])}</>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 2 }}>
                {listUsers?.map((group: any, indexGroup: number) => (
                  <Box key={indexGroup} sx={{ display: 'flex', mt: 1, alignItems: 'baseline' }}>
                    <Accordion sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 3 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.8rem' }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Box sx={{ display: 'flex' }}>
                          <Typography variant="body2">{group[0]?.group_name}</Typography>
                          <Avatar
                            sx={{
                              ml: 2,
                              width: 20,
                              height: 20,
                              border: `1px solid ${theme.palette.primary.main}`,
                              bgcolor: theme.palette.action.hover,
                              color: 'primary.dark',
                              fontSize: '1.2rem',
                            }}
                          >
                            {group?.length}
                          </Avatar>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container sx={{ justifyContent: 'space-between' }}>
                          {group?.map((user: any, indexUser: number) => (
                            <Grid
                              item
                              xs={12}
                              md={5.9}
                              key={indexUser}
                              sx={{
                                borderRadius: 2,
                                mb: 1.5,
                                padding: 2,
                                backgroundColor: 'action.hover',
                                boxShadow: 3,
                              }}
                            >
                              <Box sx={{ display: 'flex' }}>
                                <Typography variant="caption" sx={{ color: 'text.secondary', alignSelf: 'center' }}>
                                  {t('management.asm.groups.status')}
                                </Typography>
                                {user?.is_active ? (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: '#02bb9f',
                                      backgroundColor: 'rgba(2, 187, 159, 0.1)',
                                      ml: 1,
                                      borderRadius: '5px',
                                      padding: '2px 5px',
                                    }}
                                  >
                                    {t('management.asm.groups.active')}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: '#f9806c',
                                      backgroundColor: '#ffffff1a',
                                      ml: 1,
                                      borderRadius: '5px',
                                      padding: '2px 5px',
                                    }}
                                  >
                                    {t('management.asm.groups.inactive')}
                                  </Typography>
                                )}
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Box sx={{ width: '35%' }}>
                                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    E-mail
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                                    {user?.user_name}
                                  </Typography>
                                </Box>
                                <Box sx={{ width: '35%' }}>
                                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    {t('role.role')}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: 'text.primary',
                                      fontWeight: 500,
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {user?.permission_name}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Box sx={{ width: '35%' }}>
                                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    {t('management.asm.groups.groupName')}
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                                    {user?.group_name}
                                  </Typography>
                                </Box>
                                <Box sx={{ width: '35%' }}>
                                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    {t('management.asm.groups.expireDate')}
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                                    {formatTimeCVEDetail(user?.expire_date_license)}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Tooltip title={t('tooltip.edit')} placement="bottom">
                      <EditIcon
                        onClick={() => {
                          setOpenDialogEdit(true);
                          setSelectedGroup({
                            nameGroup: group[0]?.group_name,
                            expireDate: group[0]?.expire_date_license,
                            idGroup: group[0]?.group_id,
                          });
                          setValue('expireDate', group[0]?.expire_date_license);
                          setValue('groupName', group[0]?.group_name);
                        }}
                        sx={{
                          marginLeft: 1.5,
                          marginRight: 1.5,
                          fontSize: '2rem',
                          cursor: 'pointer',
                          '&:hover': {
                            color: 'info.main',
                          },
                        }}
                      />
                    </Tooltip>

                    <Dialog
                      open={openDialogEdit}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => {
                        setOpenDialogEdit(false);
                      }}
                      aria-describedby="alert-dialog-slide-description"
                      sx={{
                        '& .MuiDialog-container': {
                          '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: '400px',
                            backgroundColor: 'background.dark',
                            border: `1px solid ${theme.palette.divider}`,
                          },
                        },
                      }}
                      BackdropProps={{
                        style: {
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        },
                      }}
                    >
                      <DialogTitle>
                        <Typography fontSize={16} sx={{ fontWeight: 600 }}>
                          {t('dialog.editGroup')}
                        </Typography>
                      </DialogTitle>
                      <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                              name="groupName"
                              control={control}
                              defaultValue={selectedGroup.nameGroup}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  type="text"
                                  style={{ width: '100%' }}
                                  label={t('management.asm.groups.groupName')}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  id="input-with-icon-textfield"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <GroupsIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  variant="standard"
                                />
                              )}
                            />
                            <Controller
                              name="expireDate"
                              control={control}
                              defaultValue={selectedGroup.expireDate}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  type="date"
                                  style={{ width: '100%', marginTop: '20px' }}
                                  label={t('management.asm.groups.expireDate')}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  id="input-with-icon-textfield"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <CalendarMonthIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  // eslint-disable-next-line react/jsx-no-duplicate-props
                                  inputProps={{
                                    min: new Date().toISOString().split('T')[0],
                                  }}
                                  variant="standard"
                                />
                              )}
                            />
                          </form>
                          <UpdateButton handleClick={handleSubmit(onSubmit)} />
                        </Box>
                      </DialogContent>
                    </Dialog>

                    <Tooltip title={t('tooltip.delete')} placement="bottom">
                      <DeleteIcon
                        onClick={() => {
                          setOpenDialogDelete(true);
                          setGroupNameDelete(group[0]?.group_name);
                        }}
                        sx={{
                          fontSize: '2rem',
                          cursor: 'pointer',
                          '&:hover': {
                            color: 'error.main',
                          },
                        }}
                      />
                    </Tooltip>
                    <ConfirmDeleteModal
                      openDialogDelete={openDialogDelete}
                      setOpenDialogDelete={setOpenDialogDelete}
                      handleDelete={handleGroupDelete}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default UserManagement;
