import { useCallback, useEffect, useRef, useState } from 'react';

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  LinearProgress,
  Button,
  CardMedia,
  Backdrop,
} from '@mui/material';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Transition } from '@/components/common/Transition';
import { useCommonInfo } from '@/contexts/Common';
import useNotify from '@/Hooks/common/useNotify';
import useManagenent from '@/Hooks/fetchApi/useManagenent';

interface AddCourseProps {
  openDialogCreate: boolean;
  onDialogCreateChange: (newValue: boolean) => void;
}

const AddCourse: React.FC<AddCourseProps> = ({ openDialogCreate, onDialogCreateChange }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const { handleUploadCourse } = useManagenent();
  const { onNotify } = useNotify();
  const [searchParams, setSearchParams] = useSearchParams();
  const { apiError } = useCommonInfo();

  const [file, setFile] = useState<FileWithPath | null>(null);
  const [thumbnailFile, setThumbnailFile] = useState<FileWithPath | null>(null);
  const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [courseName, setCourseName] = useState<string>('');
  const [courseNameError, setCourseNameError] = useState<boolean>(false);
  const [idVideo, setIdVideo] = useState<string>(uuidv4());

  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB mỗi phần

  useEffect(() => {
    if (openDialogCreate && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogCreate]);

  // Sử dụng react-dropzone để xử lý video và thumbnail
  const { getRootProps: getVideoRootProps, getInputProps: getVideoInputProps } = useDropzone({
    accept: { 'video/*': [] },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
  });

  const { getRootProps: getThumbnailRootProps, getInputProps: getThumbnailInputProps } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const fileThumbnail = acceptedFiles[0];
        setThumbnailFile(fileThumbnail);
        setThumbnailPreview(URL.createObjectURL(fileThumbnail));
      }
    },
  });

  const handleClose = useCallback(() => {
    setFile(null);
    setThumbnailFile(null);
    setThumbnailPreview(null);
    setCourseName('');
    setUploadProgress(0);
    setUploading(false);
    onDialogCreateChange(false);
    setIdVideo(uuidv4());
    setSearchParams({ page: '1' });
  }, []);

  const onSubmit = async () => {
    if (courseName === '') {
      setCourseNameError(true);
      return;
    }
    setCourseNameError(false);

    if (!file || !thumbnailFile) return;

    setUploading(true);
    const totalChunks: number = Math.ceil(file.size / CHUNK_SIZE);
    let uploadedSize = 0;

    for (let i: number = 0; i < totalChunks; i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('name_video', file.name);
      formData.append('upload_id', idVideo);
      formData.append('start_size_video', start.toString());
      formData.append('end_size_video', end.toString());
      formData.append('size_video', file.size.toString());
      formData.append('mine_type_video', file.type);
      formData.append('title', courseName);
      formData.append('thumbnail', thumbnailFile);
      formData.append('mine_type_thumbnail', thumbnailFile.type);

      // eslint-disable-next-line no-await-in-loop
      await handleUploadCourse(formData);

      // Cập nhật tiến trình upload
      uploadedSize += chunk.size;
      setUploadProgress(Math.round((uploadedSize / file.size) * 100));
    }
    setUploading(false);
    handleClose();
    onNotify('success', t('notify.uploadCourse'), 3000);
  };

  useEffect(() => {
    apiError && handleClose();
  }, [apiError, handleClose]);

  return (
    <>
      <Dialog
        open={openDialogCreate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogCreateChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography
            onClick={() => {
              handleClose();
            }}
            sx={{ fontSize: '2rem', marginTop: 1 }}
          >
            {t('management.training.addCourse')}
          </Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <Box sx={{ backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('management.training.courseName')}
              </Typography>
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                autoFocus
                placeholder={t('placeholder.courseName')}
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                error={courseNameError}
                helperText={courseNameError ? t('validation.fieldRequired') : null}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('management.training.thumbnail')}
              </Typography>
              <Box
                {...getThumbnailRootProps()}
                sx={{
                  border: '2px dashed grey',
                  borderRadius: 2,
                  padding: 2,
                  textAlign: 'center',
                  marginTop: 2,
                  cursor: 'pointer',
                  backgroundColor: 'background.paper',
                }}
              >
                <input {...getThumbnailInputProps()} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('placeholder.dropzone')}
                </Typography>
                {thumbnailFile && (
                  <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                    {thumbnailFile.name}
                  </Typography>
                )}
              </Box>
              {thumbnailPreview && (
                <Box sx={{ marginTop: 2 }}>
                  <CardMedia
                    component="img"
                    image={thumbnailPreview}
                    alt="Thumbnail Preview"
                    sx={{ height: 140, width: 'auto', borderRadius: 1 }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Video
              </Typography>
              <Box
                {...getVideoRootProps()}
                sx={{
                  border: '2px dashed grey',
                  borderRadius: 2,
                  padding: 2,
                  textAlign: 'center',
                  marginTop: 2,
                  cursor: 'pointer',
                  backgroundColor: 'background.paper',
                }}
              >
                <input {...getVideoInputProps()} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('placeholder.dropzone')}
                </Typography>
                {file && (
                  <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                    {file.name}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Button variant="contained" color="primary" onClick={onSubmit} disabled={!file || uploading} sx={{ mt: 3 }}>
            {uploading ? t('action.uploading') : t('action.upload')}
          </Button>
        </DialogContent>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: 99999 }} open={uploading}>
        <Box sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', width: { xs: '70vw', md: '50vw' } }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body1" color="#fff">
              {`${Math.round(uploadProgress)}%`}
            </Typography>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};
export default AddCourse;
