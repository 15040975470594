import { useState } from 'react';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  Box,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Table,
  TableBody,
  useTheme,
  Tooltip,
  Pagination,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useNotify from '@/Hooks/common/useNotify';
import useManagenent from '@/Hooks/fetchApi/useManagenent';
import { PAGE_SIZE } from '@/utils/constants';

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

interface User {
  user_name: string;
}

interface ListIdentityProps {
  listUsers: [];
  page: number;
  onPageChange: (newValue: number) => void;
  totalRow: number;
  handleGetListPermission: (arg1: string, arg2: number) => Promise<void>;
}

const ListIdentity: React.FC<ListIdentityProps> = ({
  listUsers,
  page,
  onPageChange,
  totalRow,
  handleGetListPermission,
}) => {
  const { handlePermissionIdentity } = useManagenent();
  const { t } = useTranslation();
  const theme = useTheme();
  const { onNotify } = useNotify();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogDeleteAll, setOpenDialogDeleteAll] = useState(false);
  const [selected, setSelected] = useState<readonly User[]>([]);
  const [userDelete, setUserDelete] = useState<any>({});

  const navigateDeleteSuccess = () => {
    onNotify('success', t('notify.removePermission'), 3000);
    setOpenDialogDelete(false);
    setOpenDialogDeleteAll(false);
    setSelected([]);
    onPageChange(1);
    if (page === 1) {
      handleGetListPermission('', 1);
    }
  };

  const handleDeleteAll = () => {
    const listUsernameSelected = selected.map((user: User) => user.user_name);
    const params = {
      list_user: listUsernameSelected,
      type: 'delete',
    };
    handlePermissionIdentity(params, navigateDeleteSuccess);
  };

  const handleDelete = () => {
    const params = {
      list_user: [userDelete.user_name],
      type: 'delete',
    };
    handlePermissionIdentity(params, navigateDeleteSuccess);
  };

  const handleChangePage = (pageValue: number) => {
    onPageChange(pageValue);
    setSelected([]);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow sx={{ backgroundColor: 'action.hover' }}>
          <TableCell sx={{ width: '10%' }} padding="checkbox">
            {!_.isEmpty(listUsers) && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            )}
          </TableCell>

          <TableCell sx={{ width: '35%' }}>{t('table.permission.username')}</TableCell>
          <TableCell sx={{ width: '40%' }}>{t('table.permission.expireDate')}</TableCell>
          <TableCell sx={{ width: '15%', padding: '5px', textAlign: 'center' }}>
            {numSelected > 0 && (
              <Button
                variant="contained"
                fullWidth
                type="button"
                color="error"
                onClick={() => {
                  setOpenDialogDeleteAll(true);
                }}
                sx={{
                  width: 'fit-content',
                  minWidth: '30px',
                  textTransform: 'none',
                  fontSize: '1.2rem',
                  whiteSpace: 'nowrap',
                  padding: '4px 8px',
                }}
              >
                {t('action.reset')}
              </Button>
            )}
          </TableCell>
          <ConfirmDeleteModal
            openDialogDelete={openDialogDeleteAll}
            setOpenDialogDelete={setOpenDialogDeleteAll}
            handleDelete={handleDeleteAll}
          />
        </TableRow>
      </TableHead>
    );
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(listUsers);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, user_name: string) => {
    const selectedIndex = selected.findIndex((item: any) => item.user_name === user_name);
    let newSelected: readonly User[] = [];
    if (selectedIndex === -1) {
      const userToAdd: any = listUsers.find((user: any) => user.user_name === user_name);
      if (userToAdd) {
        newSelected = newSelected.concat(selected, userToAdd);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const isSelected = (user_name: string) => selected.findIndex((item: any) => item.user_name === user_name) !== -1;

  return (
    <>
      <TableContainer
        sx={{
          padding: 2,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'action.hover',
          mt: 3,
        }}
      >
        <Table aria-label="simple table" sx={{ tableLayout: 'fixed' }}>
          <EnhancedTableHead
            numSelected={selected?.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={listUsers?.length}
          />
          <TableBody>
            <>
              {!_.isEmpty(listUsers) ? (
                <>
                  {listUsers.map((row: any, index: number) => (
                    <TableRow
                      key={index}
                      hover
                      tabIndex={-1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          role="checkbox"
                          onClick={(event) => handleClick(event, row.user_name)}
                          checked={isSelected(row.user_name)}
                          inputProps={{
                            'aria-labelledby': `enhanced-table-checkbox-${index}`,
                          }}
                        />
                      </TableCell>

                      <TableCell>{row.user_name}</TableCell>
                      <TableCell>{row.expire_date_license}</TableCell>

                      <TableCell sx={{ minWidth: '100px', textAlign: 'center' }}>
                        <Tooltip title={t('action.reset')} placement="bottom">
                          <RestartAltIcon
                            onClick={() => {
                              setOpenDialogDelete(true);
                              setUserDelete(row);
                            }}
                            sx={{
                              fontSize: '2rem',
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'error.main',
                              },
                            }}
                          />
                        </Tooltip>
                        <ConfirmDeleteModal
                          openDialogDelete={openDialogDelete}
                          setOpenDialogDelete={setOpenDialogDelete}
                          handleDelete={handleDelete}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                    {t('notify.noData')}
                  </TableCell>
                </TableRow>
              )}
            </>
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalRow / PAGE_SIZE)}
            color="primary"
            onChange={(event, value) => {
              handleChangePage(value);
            }}
            page={page}
          />
        </Box>
      </TableContainer>
    </>
  );
};
export default ListIdentity;
