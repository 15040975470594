import React, { forwardRef } from 'react';

import { Typography, Button, Dialog, DialogContent, DialogActions, Slide, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  openDialogDelete: boolean;
  setOpenDialogDelete: (newValue: boolean) => void;
  handleDelete: () => void;
}

const ConfirmDeleteModal: React.FC<Props> = ({ openDialogDelete, setOpenDialogDelete, handleDelete }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Dialog
      open={openDialogDelete}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setOpenDialogDelete(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
            backgroundColor: 'background.dark',
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: 'none',
          },
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <DialogContent>
        <Typography fontSize={16} sx={{ fontWeight: 600 }}>
          {t('notify.confirmDelete')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            setOpenDialogDelete(false);
          }}
        >
          {t('action.cancel')}
        </Button>
        <Button
          onClick={() => {
            handleDelete();
          }}
          variant="contained"
          color="error"
          sx={{
            ml: '30px !important',
          }}
        >
          {t('action.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDeleteModal;
