import { useTranslation } from 'react-i18next';

import { httpStatus } from '@/configs/Enums/httpStatus';
import useManagementService from '@/services/management';
import { ReportNewsParams } from '@/types/management';

import useLoadingGif from '../common/useLoadingGif';
import useNotify from '../common/useNotify';

type ResSuccessType = () => void;

const useManagenent = () => {
  const {
    listNews,
    editNews,
    deleteNews,
    getListPermissionIdentity,
    permissionIdentity,
    getList2FA,
    disable2FA,
    uploadCourse,
    getListCourses,
    getListReport,
    sendReportNews,
  } = useManagementService();
  const { setLoadingGif } = useLoadingGif();
  const { onNotify } = useNotify();
  const { t } = useTranslation();

  const handleListNews = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await listNews(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleUpdateNews = async (body: any, updateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await editNews(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.updateNews'), 3000);
      updateSuccess();
      setLoadingGif(false);
    }
  };

  const handleDeleteNews = async (body: any, deleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteNews(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.deleteNews'), 3000);
      deleteSuccess();
      setLoadingGif(false);
    }
  };

  const handleGetListPermissionIdentity = async (body: any) => {
    setLoadingGif(true);
    const res: any = await getListPermissionIdentity(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handlePermissionIdentity = async (body: any, navigateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await permissionIdentity(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        navigateSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleGetList2FA = async (body: any) => {
    setLoadingGif(true);
    const res: any = await getList2FA(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleDisable2FA = async (body: any, deleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await disable2FA(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.removePermission'), 3000);
        deleteSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleUploadCourse = async (body: any) => {
    const res: any = await uploadCourse(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        //
        break;
      default:
        break;
    }
  };

  const handleGetListCourses = async (body: any) => {
    setLoadingGif(true);
    const res: any = await getListCourses(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleGetListReport = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getListReport(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleSendReportNews = async (body: ReportNewsParams, sendReportSuccess: ResSuccessType) => {
    setLoadingGif(true);
    const res: any = await sendReportNews(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('info', t('notify.sendReport'), 3000);
        sendReportSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  return {
    handleListNews,
    handleUpdateNews,
    handleDeleteNews,
    handleGetListPermissionIdentity,
    handlePermissionIdentity,
    handleGetList2FA,
    handleDisable2FA,
    handleUploadCourse,
    handleGetListCourses,
    handleGetListReport,
    handleSendReportNews,
  };
};

export default useManagenent;
